import React from "react";
import { getUserTypeName, userTypes } from "../../../constant/userManagement";
import Tooltip from "../../../utils/tooltip";

export const columns = ({
  onSelectUser,
  toggleAddEditUserPanel = null,
  onDeleteUser = null,
  userDetails = null,
  detailsView = false,
  listOf = null,
  loggedInAs = null,
}) => [
    {
      name: "User",
      accessor: "fullName",
      className: "col-user-id",
      headerClassName: "col-user-id-header",
      render: (row) => (
        <div
          className="text-primary pointer ellipsis"
          onClick={() => onSelectUser(row)}>
          {row.fullName}
        </div>
      ),
    },
    {
      name: "Role",
      className: "col-role",
      sortable:
        userDetails &&
        userDetails.userType !== userTypes.AD &&
        userDetails.userType !== userTypes.CA,
      headerClassName: "col-role-header",
      hide:
        userDetails &&
        ((userDetails.userType === userTypes.AD && !listOf) ||
          userDetails.userType === userTypes.SM),
      render: (row) => {
        return <div>{getUserTypeName(row.userType)}</div>;
      },
    },
    {
      name: "Company",
      accessor: "companyName",
      className: "col-company",
      sortable: userDetails && userDetails.userType === userTypes.AD,
      hide:
        (detailsView && userDetails.userType === userTypes.AD) ||
        (userDetails &&
          (userDetails.userType === userTypes.CA ||
            userDetails.userType === userTypes.SM)),
      headerClassName: "col-company-header",
    },
    {
      name: "Market",
      accessor: "market",
      className: "col-market",
      headerClassName: "col-market-header",
      sortable: false,
    },
    {
      name: "Email ID",
      accessor: "emailAddress",
      className: "col-email-address",
      headerClassName: "col-email-address-header",
    },
    {
      name: "Status",
      className: "col-status",
      headerClassName: "col-status-header",
      sortable: false,
      render: (row) => {
        return (
          <div
            className={`badge badge-${row.isActive ? "success" : "secondary"}`}>
            {row.isActive ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      name: "Actions",
      className: "col-actions text-right",
      headerClassName: "col-actions-header",
      sortable: false,
      hide:
        userDetails.userType === userTypes.SM ||
        !onDeleteUser ||
        !toggleAddEditUserPanel,
      render: (row) => {
        return (
          <div className="d-flex justify-content-end">
            <Tooltip
              message="Edit"
              component={
                <button
                  type="button"
                  className="btn btn-cell btn-default"
                  onClick={() => toggleAddEditUserPanel(row)}>
                  <i className="icon icon-edit" />
                </button>
              }
            />
            {loggedInAs("AD") && (
              <Tooltip
                message="Reallocate License"
                component={
                  <button
                    type="button"
                    className="btn btn-cell btn-default reallocate-btn ml-2"
                    onClick={() => toggleAddEditUserPanel(row, true, true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-key-square">
                      <path d="M12.4 2.7a2.5 2.5 0 0 1 3.4 0l5.5 5.5a2.5 2.5 0 0 1 0 3.4l-3.7 3.7a2.5 2.5 0 0 1-3.4 0L8.7 9.8a2.5 2.5 0 0 1 0-3.4z" />
                      <path d="m14 7 3 3" />
                      <path d="m9.4 10.6-6.814 6.814A2 2 0 0 0 2 18.828V21a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1h.172a2 2 0 0 0 1.414-.586l.814-.814" />
                    </svg>
                  </button>
                }
              />
            )}
            <Tooltip
              message="Delete"
              component={
                <button
                  type="button"
                  className="btn btn-cell btn-default ml-2"
                  onClick={() => onDeleteUser(row)}>
                  <i className="icon icon-trash" />
                </button>
              }
            />
          </div>
        );
      },
    },
  ];
