import React from "react";
import { Field } from "redux-form";
import FormGroup from "../../utils/formGroup";

const TemplateSettings = ({ fields, isUserSettingsLoading }) => {
	return (
		<>
			<h3 className="color-1 text-uppercase">Template Settings</h3>
			<div className="row">
				<div className="col-12">
					<div className="row">
						<div className="col-4">
							<Field
								component={FormGroup}
								{...fields.base64FileString}
								isLoading={isUserSettingsLoading}
							/>
							<Field component={FormGroup} {...fields.website}
								isLoading={isUserSettingsLoading}
							/>
						</div>
						<div className="col-7">
							<div className="row">
								<Field
									component={FormGroup}
									{...fields.faceBookUrl}
									isLoading={isUserSettingsLoading}
								/>
								<Field
									component={FormGroup}
									{...fields.youtubeUrl}
									isLoading={isUserSettingsLoading}
								/>
								<Field
									component={FormGroup}
									{...fields.linkedInUrl}
									isLoading={isUserSettingsLoading}
								/>
								<Field
									component={FormGroup}
									{...fields.twitterUrl}
									isLoading={isUserSettingsLoading}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TemplateSettings;
