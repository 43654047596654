import React, { useReducer } from "react";
import { Field } from "redux-form";
import FormGroup from "../../utils/formGroup";
import { getUtcDateFromDatePicker } from "../../utils/helper";
import { errorMessage } from "../../utils/errorMessage";

import { get } from "lodash";

const initialStates = {
	selectedDate: {},
	isResult: null,
	usageCount: 0,
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const DataSource = ({
	lastSyncInfo,
	dataUsageCompanyList,
	getCompanyDataUsage,
	formValues,
	isCompanyListLoading,
	isAPILoading
}) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const { selectedDate, isResult, usageCount } = state;
	const dataSourceList = [
		{ key: "syncTime", label: "Sync Time" },
		{ key: "syncFrequency", label: "Sync Frequency" },
		{ key: "lastSyncDate", label: "Last Sync Date" },
		// { key: "lastSyncTime", label: "Last Sync Time" },
	];

	const providerName = [
		{ value: "Attom Data", label: "Attom Data" },
		{ value: "Twilio", label: "Twilio" },
		{ value: "Lob", label: "Lob" },
	];

	const dateFields = {
		fromDate: {
			name: "fromDate",
			label: "Date From",
			key: "fromDate",
			type: "datepicker",
			selectedDate:
				selectedDate && selectedDate.fromDate
					? selectedDate.fromDate
					: "",
			onChange: (value) => {
				dispatch({
					selectedDate: {
						...selectedDate,
						fromDate: value,
					},
				});
				dispatch({ isResult: false });
			},
			maxDate:
				selectedDate && selectedDate.toDate ? selectedDate.toDate : "",
			formGroupClass: "w-15 pr-2 pl-2",
			className: "form-control",
		},
		toDate: {
			name: "toDate",
			label: "Date To",
			key: "toDate",
			type: "datepicker",
			selectedDate:
				selectedDate && selectedDate.toDate ? selectedDate.toDate : "",
			onChange: (value) => {
				dispatch({
					selectedDate: {
						...selectedDate,
						toDate: value,
					},
				});
				dispatch({ isResult: false });
			},
			minDate:
				selectedDate && selectedDate.fromDate
					? selectedDate.fromDate
					: "",
			formGroupClass: "w-15 pr-2",
		},
	};
	const getCompanyDataUsageCount = () => {
		if (formValues.companyUserId && formValues.providerName) {
			getCompanyDataUsage(
				{
					companyUserId: formValues.companyUserId.value,
					fromDate: formValues.fromDate
						? encodeURIComponent(
							getUtcDateFromDatePicker(formValues.fromDate)
						)
						: "",
					toDate: formValues.toDate
						? encodeURIComponent(
							getUtcDateFromDatePicker(formValues.toDate)
						)
						: "",
				},
				(result) => {
					const resultSet = result.result.filter(
						(item) =>
							item.providerName === formValues.providerName.value
					);
					resultSet.length &&
						dispatch({ usageCount: resultSet[0]["usageCount"] });
					dispatch({ isResult: true });
				}
			);
		} else {
			errorMessage(4001);
		}
	};
	const getDataSource = () => {
		return dataSourceList.map((value, index) => (
			<div
				key={index}
				className="settings-datasource-group flex-fill mr-4"
			>
				<div className="settings-datasource-style pt-2">
					{get(lastSyncInfo, value.key)}
				</div>
				<span className="settings-datasource-form-label">
					{value.label}
				</span>
			</div>
		));
	};
	return (
		<>
			<h2 className="mb-2">Data Sources</h2>
			<div className="card card-primary p-4 mb-4">
				<h3 className="color-1 text-uppercase">Scrape hero</h3>
				<div className="d-flex flex-columns">{getDataSource()}</div>
			</div>
			<h2 className="mb-2">API Usage</h2>
			<div className="card card-primary p-4 mb-4">
				<div className="d-flex flex-columns">
					<Field
						component={FormGroup}
						name="providerName"
						label="API"
						type="select"
						options={providerName}
						onChange={() => {
							dispatch({ isResult: false });
						}}
						formGroupClass="w-15"
						isLoading={isAPILoading}
					/>
					<Field
						component={FormGroup}
						name="companyUserId"
						label="Company"
						type="select"
						options={dataUsageCompanyList}
						onChange={() => {
							dispatch({ isResult: false });
						}}
						formGroupClass="w-25 pl-2"
						isLoading={isCompanyListLoading}
					/>

					<Field component={FormGroup} {...dateFields.fromDate} />
					<Field component={FormGroup} {...dateFields.toDate} />
					<div className="w-1-0  d-flex align-items-end mt-auto pb-3">
						<div
							onClick={() => {
								getCompanyDataUsageCount();
							}}
							className="text-primary align-self-center pointer"
						>
							View Usage &darr;
						</div>
					</div>
				</div>

				{isResult ? (
					<div className="result-block pt-4 pl-4 text-primary">
						API Usage <span className="pl-4">:</span>
						<span className="pl-4 font-weight-bold">
							{usageCount}
						</span>
						<span className="pl-1">API Calls</span>
					</div>
				) : null}
			</div>
		</>
	);
};
export default DataSource;
