import React, { useEffect, useReducer, useRef, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { get } from "lodash";
import { errorMessage } from "../../utils/errorMessage";
import {
  toggleMainLoader,
  getReportZipcodeList,
  realtorReport,
  getCompanyMarkets,
} from "../../store/actions";

import { downloadFile, doughnutStyles } from "./helper";

import { reduxForm } from "redux-form";
import { CustomInput, NoOptionsMessage, VirtualizedMenuList } from "../../utils/formGroup";
import "./report.scss";

const reducer = (state, action) => {
  return { ...state, ...action };
};

const initialStates = {
  dataLoaded: false,
};

const RealtorReports = (props) => {
  const {
    getReportZipcodeList,
    getCompanyMarkets,
    realtorReport,
    userDetails,
    toggleMainLoader,
    handleSubmit,
    zipCodes,
    marketLists,
    loadingStates,
    loadingStatesMarket
  } = props;

  const [state, dispatch] = useReducer(reducer, initialStates);
  const [editMarketList, setEditMarketList] = useState([]);
  const [filterZipCode, setFilterZipCode] = useState(true);
  const [selectedZip, setSelectedZip] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState([]);
  const marketRef = useRef("");
  const zipRef = useRef("");

  const { dataLoaded } = state;

  const fetchReportData = async (apiCall) => {
    try {
      const response = await apiCall();
      return { success: true, data: response };
    } catch (error) {
      errorMessage(error);
      return { success: false, error };
    }
  };


  useEffect(() => {
    const loadReportData = async () => {
      if (!userDetails?.userId) return;

      toggleMainLoader(true);

      try {
        const apiCalls = await Promise.allSettled([
          fetchReportData(() => getReportZipcodeList()),
          fetchReportData(() => getCompanyMarkets())
        ]);

        const allSuccessful = apiCalls.every(
          (result) => result.status === 'fulfilled' && result.value.success
        );

        if (allSuccessful) {
          dispatch({
            dataLoaded: true,

          });
        } else {
          errorMessage('Failed to load some report data');
        }
      } catch (error) {
        errorMessage(error);
      } finally {
        toggleMainLoader(false);
      }
    };

    loadReportData();
  }, [userDetails, getReportZipcodeList, getCompanyMarkets, toggleMainLoader]);

  useEffect(() => {
    const formatMarketLists = () => {
      const formatLists = marketLists?.map((item) => {
        return {
          label: item.name,
          value: item.marketId,
        };
      });
      setEditMarketList(formatLists);
    };
    formatMarketLists();
  }, [marketLists]);

  const submitForm = (values = null) => {
    if ((!selectedZip?.length) && (!selectedMarket?.length)) {
      return errorMessage("Empty_Field");
    }
    else {

      let body;
      filterZipCode
        ? (body = {
          zipCodes: selectedZip?.map((item) => {
            return item.value;
          }),
        })
        : (body = {
          marketIds: selectedMarket?.map((item) => {
            return item.value;
          }),
        });
      toggleMainLoader(true);
      const successHandler = (data) => {
        toggleMainLoader(false);
        downloadFile(data.result, `Moovsoon_realtorreport`);
      };
      const errorHandler = (event) => {
        errorMessage(event);
        toggleMainLoader(false);
      };

      body && realtorReport(body, successHandler, errorHandler);
    }

  };
  const renderSubmitButton = (type, label) => {
    return (
      <button
        type="button"
        onClick={handleSubmit((values) => submitForm(values))}
        className="btn btn-primary btn-md action-button mr-4"
      >
        <i className={`pr-2 icon icon-${type}`}></i>
        <span>{label}</span>
      </button>
    );
  };
  const onSelectChange = (type, value) => {
    if (type === "market") {
      setFilterZipCode(false);
      zipRef.current.state.value = [];
      setSelectedZip([])
      setSelectedMarket(value || []);
    } else {
      setFilterZipCode(true);
      marketRef.current.state.value = [];
      setSelectedMarket([])
      setSelectedZip(value || []);
    }
  };

  return (
    <section className="moovsoon-page-container pt-0">
      <div className="moovsoon-page-body">
        {dataLoaded ? (
          <>
            <div className="pt-20 pb-20 w-100">
              <h2 className="mr-3 mb-0">Realtor Reports</h2>
            </div>
            <div className="d-flex">
              <div className="w-100">
                <form
                  className="report-form"
                  onSubmit={handleSubmit(submitForm)}
                >
                  <div className="card card-primary px-4 pt-2 pb-1 mb-3 p-4">
                    <div className="panel-switch  active">
                      <h3 className="color-1 text-uppercase mb-0">Filter</h3>
                      <span className="label-text">
                        Please choose either Zip Code or Market to download realtor report.
                      </span>
                      <div className="form-container">
                        <div className="d-flex align-items-start">
                          <div className="col-4 pr-2 form-group">
                            <Select
                              ref={zipRef}
                              className="custom-select-box"
                              isMulti
                              value={selectedZip ? selectedZip.value : []}
                              options={zipCodes}
                              styles={doughnutStyles}
                              onChange={(zip) => onSelectChange("zip", zip)}
                              isLoading={loadingStates.zipCodes}
                              placeholder={loadingStates.zipCodes ? "Loading..." : "Select"}
                              components={{
                                NoOptionsMessage: (props) => (
                                  <NoOptionsMessage {...props} isLoading={loadingStates.zipCodes} />
                                ),
                                Input: CustomInput,
                                MenuList: VirtualizedMenuList
                              }}
                            />
                            <span className="form-label">Zip Code</span>
                          </div>

                          <div className="col-4 pr-2 form-group">
                            <Select
                              ref={marketRef}
                              className="custom-select-box"
                              isMulti
                              value={selectedMarket ? selectedMarket.value : []}
                              options={editMarketList}
                              styles={doughnutStyles}
                              onChange={(market) => onSelectChange("market", market)}
                              isLoading={loadingStatesMarket.companyMarkets}
                              placeholder={loadingStatesMarket.companyMarkets ? "Loading..." : "Select"}
                              components={{
                                NoOptionsMessage: (props) => (
                                  <NoOptionsMessage {...props} isLoading={loadingStatesMarket.companyMarkets} />
                                ),
                                Input: CustomInput,
                                MenuList: VirtualizedMenuList
                              }}
                            />
                            <span className="form-label">Market</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-row justify-content-end">
                    {renderSubmitButton("download", "Download")}
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    userDetails: get(state, "Auth.userDetails", {}),
    zipCodes: get(state, "Report.zipCodes", {}),
    marketLists: get(state, "Market.companyMarkets", []),
    loadingStates: get(state, "Report.loading", {}),
    loadingStatesMarket: get(state, "Market.loadingStates", {})
  };
};

const mapDispatchToProps = {
  getReportZipcodeList,
  getCompanyMarkets,
  toggleMainLoader,
  realtorReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "RealtorReportForm",
    enableReinitialize: true,
  })(RealtorReports)
);
