import { MARKET, PROPERTY } from "../types";

export default (
	state = {
		propertyList: [],
		propertyFilter: {
			sortColumn: "dateAdded",
			sortDirection: "desc",
			pageIndex: 1,
			pageSize: 10,
			searchKey: "",
			filters: [{ key: "status", value: "1", label: "Status" }],
		},
		propertyDetails: null,
		propertyOwnerInfo: null,
		loadingStates: {
			propertyDetails: true,
		}
	},
	action
) => {
	switch (action.type) {
		case PROPERTY.GET_PROPERTIES:
			return {
				...state,
				propertyList: action.payload ? action.payload.result : [],
				propertyDetails: []
			};
		case PROPERTY.GET_PROPERTY_SAVED_FILTERS:
			return {
				...state,
				propertySavedFilters: action.payload
					? action.payload.result
					: [],
			};
		case PROPERTY.GET_PROPERTY_ZIPCODES:
			return {
				...state,
				propertyZipcodes: (() => {
					const zipCodes = action.payload
						? [
							{
								value: "",
								label: "Select",
							},
							...action.payload.result,
						]
						: [];

					return zipCodes;
				})(),
			};
		case PROPERTY.GET_PROPERTY_STATUSES:
			return {
				...state,
				propertyStatuses: (() => {
					const statuses = action.payload
						? [
							...action.payload.result,
						]
						: [];

					return statuses;
				})(),
			};
		case PROPERTY.GET_PROPERTY_REALTORS:
			return {
				...state,
				propertyRealtors: (() => {
					const realtors = action.payload
						? [
							{
								value: "",
								label: "Select",
							},
							...action.payload.result,
						]
						: [];

					return realtors;
				})(),
			};
		//KAN-52 - maps the result into dropdown
		case PROPERTY.GET_PROPERTY_CHILD_MARKETS:
			return {
				...state,
				propertyChildMarkets: (() => {
					const childMarkets = action.payload
						? [
							{
								value: "",
								label: "Select",
							},
							...action.payload.result,
						]
						: [];

					return childMarkets;
				})(),
			};
		case PROPERTY.GET_PROPERTY_DETAILS:
			return {
				...state,
				propertyDetails: action.payload ? action.payload.result : [],
				loadingStates: {
					...state.loadingStates,
					propertyDetails: false
				}
			};
		case PROPERTY.CLEAR_PROPERTY_DETAILS:
			return {
				...state,
				propertyDetails: []
			};
		case PROPERTY.SET_PROPERTY_FILTER:
			return {
				...state,
				propertyFilter: action.payload ? action.payload : {},
			};
		case PROPERTY.SET_PERSIST_FILTER:
			return {
				...state,
				persistFilter: action.payload ? action.payload : {},
			};
		case MARKET.GET_ALL_MARKET_TEMPLATES:
			return {
				...state,
				templateList: action.payload ? action.payload.result : {},
			};
		case PROPERTY.RESET_LOADING_STATES:
			return {
				...state,
				loadingStates: {
					...state.loadingStates,
					propertyDetails: true,
				}
			};
		default:
			return state;
	}
};
