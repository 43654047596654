import React, { useEffect, useState, useReducer } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { errorMessage } from "../../utils/errorMessage";
import {
	toggleMainLoader,
	getReportZipcodeList,
	getReportRealtorList,
	getReportStatusList,
	getReportSourceList,
	getReportMoveTypeList,
	getReportUserList,
	getReportClumnList,
} from "../../store/actions";

import ListingReport from "./listingReport";
import OperationReport from "./operationsReport";
import ConversionReport from "./conversionReport";
import PipelineReport from "./PipelineReport";
import "./report.scss";

import { Tabs, Tab } from "react-bootstrap";

const reducer = (state, action) => {
	return { ...state, ...action };
};

const initialStates = {
	dataLoaded: false,
};

const Reports = (props) => {
	const {
		getReportZipcodeList,
		getReportRealtorList,
		userDetails,
		toggleMainLoader,
		getReportStatusList,
		getReportSourceList,
		getReportMoveTypeList,
		getReportUserList,
		getReportClumnList,
	} = props;

	const [reportTab, setReportTab] = useState(1);

	const [state, dispatch] = useReducer(reducer, initialStates);

	const { dataLoaded } = state;

	const fetchReportData = async (apiCall) => {
		try {
			const response = await apiCall();
			return { success: true, data: response };
		} catch (error) {
			errorMessage(error);
			return { success: false, error };
		}
	};

	useEffect(() => {
		const loadReportData = async () => {
			if (!userDetails?.userId) return;

			toggleMainLoader(true);

			try {
				const apiCalls = await Promise.allSettled([
					fetchReportData(() => getReportZipcodeList()),
					fetchReportData(() => getReportRealtorList()),
					fetchReportData(() => getReportStatusList()),
					fetchReportData(() => getReportSourceList()),
					fetchReportData(() => getReportMoveTypeList()),
					fetchReportData(() => getReportClumnList()),
					fetchReportData(() => getReportUserList()),
				]);

				const allSuccessful = apiCalls.every(
					(result) => result.status === 'fulfilled' && result.value.success
				);

				if (allSuccessful) {
					dispatch({ dataLoaded: true });
				} else {
					errorMessage('Failed to load some report data');
				}
			} catch (error) {
				errorMessage(error);
			} finally {
				toggleMainLoader(false);
			}
		};

		loadReportData();
	}, [
		userDetails,
		getReportZipcodeList,
		getReportRealtorList,
		getReportStatusList,
		getReportSourceList,
		getReportMoveTypeList,
		getReportUserList,
		getReportClumnList,
		toggleMainLoader,
	]);

	return (
		<section className="moovsoon-page-container pt-0">
			<div className="moovsoon-page-body">
				{dataLoaded && (
					<>
						<div className="mr-auto heading-block w-10">
							<h2 className="mr-3 mb-0">Reports</h2>
						</div>
						<div className="d-flex">
							<div className="w-100">
								<Tabs
									className="template-tabs report-tabs"
									activeKey={reportTab}
									onSelect={(k) => setReportTab(k)}>
									<Tab
										eventKey={1}
										title={
											<div>
												<i className="icon icon-listing-report pr-2" />
												Listing
											</div>
										}>
										<ListingReport
											initialValues={{
												columnList_1: true,
											}}
											toggleMainLoader={toggleMainLoader}
										/>
									</Tab>
									<Tab
										eventKey={2}
										title={
											<div>
												<i className="icon icon-operations-report pr-2" />
												Operations
											</div>
										}>
										<OperationReport
											toggleMainLoader={toggleMainLoader}
										/>
									</Tab>
									<Tab
										eventKey={3}
										title={
											<div>
												<i className="icon icon-conversion-report pr-2" />
												Conversion
											</div>
										}>
										<ConversionReport
											toggleMainLoader={toggleMainLoader}
										/>
									</Tab>
									<Tab
										eventKey={4}
										title={
											<div>
												<i className="icon icon-pipeline-report pr-2" />
												Pipeline
											</div>
										}>
										<PipelineReport
											toggleMainLoader={toggleMainLoader}
										/>
									</Tab>
								</Tabs>
							</div>
						</div>
					</>
				)}
			</div>
		</section>
	);
};
const mapStateToProps = (state) => {
	return {
		userDetails: get(state, "Auth.userDetails", {}),
	};
};

const mapDispatchToProps = {
	getReportZipcodeList,
	toggleMainLoader,
	getReportRealtorList,
	getReportStatusList,
	getReportSourceList,
	getReportMoveTypeList,
	getReportUserList,
	getReportClumnList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
