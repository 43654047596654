import React from "react";
import {
  priceMax,
  priceMin,
  sizeMax,
  sizeMin,
} from "../../../constant/userManagement";

import { get } from "lodash";
import { required } from "../../../constant/formValidators";
import { outboundCommunicationType } from "../../../constant/templateManagement";
import Tooltip from "../../../utils/tooltip";
import { getFormattedZipCodes } from "../listing/helper";

const validateMinPropVal = (value, allValues) => {
  return value &&
    allValues &&
    allValues.propMinVal &&
    value.value < allValues.propMinVal.value
    ? "Select larger!"
    : undefined;
};

const validateMinPropSize = (value, allValues) => {
  return value &&
    allValues &&
    allValues.propMinSize &&
    value.value < allValues.propMinSize.value
    ? "Select larger!"
    : undefined;
};

//Mapper fucntion to create a array of selected template mappings objects
//usage save/update
export const getMarketNotificationSettings = (
  values,
  ownerTemplateProspectingEvents,
  relatorTemplateProspectingEvents,
  createMode
) => {
  let selected = [];
  if (!createMode) {
    selected = values.subMarketNotificationSettings.length
      ? values.subMarketNotificationSettings.filter((item) => {
        return (
          item.outboundProspectingType ===
          outboundCommunicationType.homeOwner.value
        );
      })[0]
      : null;
  }
  const ownerObject = {
    subNotifSettingId:
      !createMode && selected ? selected.subNotifSettingId : "",
    outboundProspectingType: outboundCommunicationType.homeOwner.value,
    turnOffAutomaticProspecting:
      values.ownerTurnOffAutomaticProspecting || false,
    subMarketId: values.parentSubMarketId ? values.parentSubMarketId.value : "",
    isActive: true,
    templateMappings: ownerTemplateProspectingEvents.map((item, index) => {
      let subNotifTemplMappingId =
        !createMode && selected
          ? selected.templateMappings.filter((selectedItem) => {
            return selectedItem.notificationEventTypeId === item.value;
          })[0]["subNotifTemplMappingId"]
          : "";
      return {
        subNotifTemplMappingId: subNotifTemplMappingId,
        notificationEventTypeId: item.value,
        emailTemplateId:
          !values.ownerTurnOffAutomaticProspecting &&
            values.automaticProspecting
            ? get(
              values,
              `select${outboundCommunicationType.homeOwner.value}emailTemplate${item.value}.value`,
              ""
            )
            : "",
        smsTemplateId:
          !values.ownerTurnOffAutomaticProspecting &&
            values.automaticProspecting
            ? get(
              values,
              `select${outboundCommunicationType.homeOwner.value}smsTemplate${item.value}.value`,
              ""
            )
            : "",
        mailerTemplateId:
          !values.ownerTurnOffAutomaticProspecting &&
            values.automaticProspecting
            ? get(
              values,
              `select${outboundCommunicationType.homeOwner.value}mailerTemplate${item.value}.value`,
              ""
            )
            : "",
      };
    }),
  };

  selected = [];
  if (!createMode) {
    selected = values.subMarketNotificationSettings.length
      ? values.subMarketNotificationSettings.filter((item) => {
        return (
          item.outboundProspectingType ===
          outboundCommunicationType.realtor.value
        );
      })[0]
      : null;
  }
  const relatorObject = {
    subNotifSettingId:
      !createMode && selected ? selected.subNotifSettingId : "",
    outboundProspectingType: outboundCommunicationType.realtor.value,
    turnOffAutomaticProspecting:
      values.realtorTurnOffAutomaticProspecting || false,
    subMarketId: values.parentSubMarketId ? values.parentSubMarketId.value : "",
    isActive: true,
    templateMappings: relatorTemplateProspectingEvents.map((item, index) => {
      let subNotifTemplMappingId =
        !createMode && selected
          ? selected.templateMappings.filter((selectedItem) => {
            return selectedItem.notificationEventTypeId === item.value;
          })[0]["subNotifTemplMappingId"]
          : "";

      return {
        notificationEventTypeId: item.value,
        subNotifTemplMappingId: subNotifTemplMappingId,
        emailTemplateId:
          !values.realtorTurnOffAutomaticProspecting &&
            values.automaticProspecting
            ? get(
              values,
              `select${outboundCommunicationType.realtor.value}emailTemplate${item.value}.value`,
              ""
            )
            : "",
        smsTemplateId:
          !values.realtorTurnOffAutomaticProspecting &&
            values.automaticProspecting
            ? get(
              values,
              `select${outboundCommunicationType.realtor.value}smsTemplate${item.value}.value`,
              ""
            )
            : "",
        mailerTemplateId:
          !values.realtorTurnOffAutomaticProspecting &&
            values.automaticProspecting
            ? get(
              values,
              `select${outboundCommunicationType.realtor.value}mailerTemplate${item.value}.value`,
              ""
            )
            : "",
      };
    }),
  };
  return [ownerObject, relatorObject];
};

export const marketFields = ({
  dispatch,
  loggedInAs,
  createMode,
  marketZipcodes,
  marketCompanies,
  companyMarkets,
  companySubMarkets,
  getCompanySubMarkets,
  checkAvailabilityFn,
  getMarketZipcodes,
  getMarketCompanies,
  availableList,
  formValues,
  initialValues,
  change,
  templateList,
  setPanel,
  onChangeParentSubMarket,
  zipRef,
  showEditZipCodes,
  setShowEditZipCodes,
  setSelectedMarketView
}) => {
  const companyUserIds = marketCompanies.map((item) => {
    return {
      label: item.fullName || item.companyName,
      value: item.userId,
    };
  });

  const subMarketTypeOptions = [
    { label: "Submarket", value: 1 },
    { label: "Bucket", value: 2 },
  ];

  const initialValueParentMarket = () => {
    const marketItem =
      initialValues && initialValues.marketId
        ? companyMarkets.filter(
          (item) => item.marketId === initialValues.marketId
        )[0]
        : null;

    return initialValues && initialValues.marketId
      ? {
        label: marketItem ? marketItem.name : null,
        value: marketItem ? marketItem.marketId : null,
      }
      : null;
  };

  const subMarketIdOptions =
    availableList && availableList.length
      ? availableList
      : companySubMarkets
        ? companySubMarkets.map((item) => {
          return {
            label: item.name,
            value: item.subMarketId,
            marketId: item.marketId,
          };
        })
        : [];


  const getSelectForZipCodeDisabledState = () => {
    if (!createMode) return false;

    if (loggedInAs("AD")) return false;

    const { subMarketType, marketUserId, parentSubMarketId } = formValues || {};

    if (subMarketType?.value === 2) {
      return !(marketUserId?.value && parentSubMarketId?.value);
    }

    return !marketUserId?.value;
  }
  return [
    {
      name: "itemName",
      label: "Market Name",
      type: "text",
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-12",
      validate: [required],
      maxlength: 150,
    },
    {
      name: "h3DefineMarket",
      label: "Define Market",
      type: "h3",
      className: "color-1 mt-4 mb-3 text-uppercase col-12",
    },
    {
      name: "county",
      label: "County",
      type: "text",
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-12",
      hide: !loggedInAs("AD"),
      validate: loggedInAs("AD") ? [required] : [],
    },
    {
      //CA
      name: "subMarketType",
      label: "Select Category",
      type: "select",
      options: subMarketTypeOptions,
      onChange: (value) => {
        if (value) {
          getMarketCompanies({
            marketType: value.value,
          });

          if (zipRef.current?.state?.value) {
            zipRef.current.state.value = [];
          }
          change("companyUserIds", null);
          change("zipCodes", null);
        }
        dispatch({ error: null });
      },
      defaultValue: loggedInAs("SM")
        ? subMarketTypeOptions[1]
        : initialValues && initialValues.subMarketType
          ? subMarketTypeOptions.filter(
            (item) => item.value === initialValues.subMarketType
          )[0]
          : null,
      isOptionDisabled: (option) => option.disabled,
      hide: loggedInAs("SM") || loggedInAs("AD") || !createMode,
      formGroupClass: "col-12",
    },
    {
      //CA
      name: "marketUserId",
      label: "Select Parent Market",
      type: "select",
      options: companyMarkets
        ? companyMarkets.map((item) => {
          return {
            label: item.name,
            value: item.marketId,
          };
        })
        : [],
      onChange: (value) => {
        if (value) {
          if (zipRef.current?.state?.value) {
            zipRef.current.state.value = [];
          }
          change("companyUserIds", null);
          change("zipCodes", null);
          getCompanySubMarkets(value.value);
          getMarketZipcodes({
            marketId: value.value,
            submarketType: 1,
          });
        }
        dispatch({ error: null });
      },
      defaultValue: initialValueParentMarket(),
      hide:
        loggedInAs("SM") ||
        !(formValues && formValues.subMarketType) ||
        !createMode,
      formGroupClass: "col-12",
    },
    {
      //CA
      name: "parentSubMarketId",
      label: `${(loggedInAs("CA") || loggedInAs("SM")) && !createMode ? "" : "Select"
        } Parent Submarket`,
      type: "select",
      options: subMarketIdOptions,
      onChange: (value) => {
        if (value) {
          loggedInAs("SM") &&
            getMarketCompanies({
              marketType: 2,
            });
          loggedInAs("CA") &&
            getMarketCompanies({
              marketType: 2,
              subMarketId: value.value,
            });
          if (zipRef.current?.state?.value) {
            zipRef.current.state.value = [];
          }
          change("zipCodes", null);
          getMarketZipcodes({
            marketId:
              formValues && formValues.marketUserId
                ? formValues.marketUserId.value
                : initialValues
                  ? initialValues.marketId
                  : value.marketId,
            parentSubmarketId: value.value,
            submarketType: 2,
          });
          onChangeParentSubMarket(value.value);
        }
        dispatch({ error: null });
      },
      defaultValue:
        initialValues &&
        initialValues.parentSubMarketId &&
        subMarketIdOptions.filter(
          (item) => item.value === initialValues.parentSubMarketId
        ),
      hide:
        !loggedInAs("SM") &&
        !(
          loggedInAs("CA") &&
          formValues &&
          formValues.subMarketType &&
          formValues.subMarketType.value === 2 &&
          createMode
        ) &&
        !(
          loggedInAs("CA") &&
          initialValues &&
          initialValues.subMarketType === 2 &&
          !createMode
        ),
      disable: (loggedInAs("CA") || loggedInAs("SM")) && !createMode,
      viewMode: (loggedInAs("CA") || loggedInAs("SM")) && !createMode,
      formGroupClass: "col-12",
    },
    {
      name: "existingZipCodes",
      hide: createMode,
      type: "div",
      className: "col-12",
      render: () => {
        const zipCodes =
          initialValues && initialValues.zipCodes && marketZipcodes.length
            ? marketZipcodes.filter((item) =>
              initialValues.zipCodes.includes(item.label)
            ).map(code => code.label)
            : []

        const { zipCodesPreview, zipCodeViewMore } = getFormattedZipCodes(zipCodes, 12)

        return <div className="form-group">
          <div className="text-primary edit-zipcode-button"
            onClick={() => setShowEditZipCodes(!showEditZipCodes)}
          >
            {showEditZipCodes ? "Hide" : "Edit"} Zip Codes
          </div>
          <div className="zip-code-button"
            onClick={() => setSelectedMarketView({
              name: initialValues.itemName,
              zipCodes: zipCodes.join(',')

            })}
          >
            {zipCodesPreview}{" "}<span className="text-primary">{zipCodeViewMore}</span>
          </div>
          <span className="form-label">Existing Zip Codes</span>
        </div>
      }
    },
    {
      name: "zipCodes",
      label: "Zip Codes",
      type: loggedInAs("AD") ? "selectWithAddForZipCodes" : "selectForZipCodes",
      hide: !createMode && !showEditZipCodes,
      multiple: true,
      numberOnly: true,
      options: marketZipcodes,
      defaultValue: initialValues?.zipCodes
        ? marketZipcodes.filter(opt =>
          initialValues.zipCodes.includes(opt.label)
        )
        : [],
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-12",
      validate: createMode ? [required] : [],
      formatCreateLabel: (data) => {
        let ExistingMarketZipcodes = marketZipcodes.some((obj) => {
          return obj.label === data;
        });
        if (initialValues?.zipCodes.includes(data)) {
          return false;
        } else if (ExistingMarketZipcodes) {
          return false;
        } else {
          return true;
        }
      },
      zipRef: zipRef,
      disable: getSelectForZipCodeDisabledState()
    },
    {
      name: "propMinVal",
      label: "Listing Min Price",
      type: "select",
      options: priceMin,
      onChange: () => dispatch({ error: null }),
      defaultValue:
        formValues &&
        priceMin.filter((item) => formValues.propMinVal === item.value),
      formGroupClass: "col-6 pr-2",
      validate: createMode ? [required] : [],
    },
    {
      name: "propMaxVal",
      label: "Listing Max Price",
      type: "select",
      options: priceMax,
      defaultValue:
        formValues &&
        priceMax.filter((item) => formValues.propMaxVal === item.value),
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-6 pl-2",
      validate: createMode ? [required, validateMinPropVal] : [],
    },
    {
      name: "propMinSize",
      label: "Listing Min Size",
      type: "select",
      options: sizeMin,
      defaultValue:
        formValues &&
        sizeMin.filter((item) => formValues.propMinSize === item.value),
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-6 pr-2",
      validate: createMode ? [required] : [],
    },
    {
      name: "propMaxSize",
      label: "Listing Max Size",
      type: "select",
      options: sizeMax,
      defaultValue:
        formValues &&
        sizeMax.filter((item) => formValues.propMaxSize === item.value),
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-6 pl-2",
      validate: createMode ? [required, validateMinPropSize] : [],
    },
//    {
//      name: "checkAvailability",
//      render: () => (
//        <Tooltip
//          message="Make sure that the new child market does not overlap with existing ones."
//          component={
//            <div className="link-btn check-availability-button" onClick={() => checkAvailabilityFn()}>
//              Check Availability
//            </div>
//          }
//        />
//      ),
//      hide: loggedInAs("AD"),
//      type: "div",
//      className: "col-12 mb-2 py-3 text-right pointer",
//    },
    {
      name: "h3AssignUser",
      label: "Owner",
      type: "h3",
      className: "color-1 mt-4 mb-3 text-uppercase col-12",
    },
    {
      name: "companyUserIds",
      label: "Existing Users",
      type: "badges",
      className: "col-12",
      hide: createMode || !loggedInAs("AD"),
      list:
        initialValues &&
          initialValues.companyUserIds &&
          initialValues.companyUserIds.length
          ? companyUserIds.filter((item) =>
            initialValues.companyUserIds.includes(item.label)
          )
          : companyUserIds,
    },
    {
      name: "companyUserIds",
      label: loggedInAs("AD") ? "Users" : "User",
      type: "select",
      multiple: loggedInAs("AD") ? true : false,
      options:
        loggedInAs("AD") &&
          initialValues &&
          initialValues.companyUserIds &&
          initialValues.companyUserIds.length
          ? companyUserIds.filter(
            (item) =>
              initialValues &&
              !initialValues.companyUserIds.includes(item.label)
          )
          : companyUserIds,
      defaultValue: loggedInAs("AD")
        ? null
        : initialValues &&
        initialValues.companyUserIds &&
        companyUserIds.length &&
        companyUserIds.filter((item) => {
          return initialValues.companyUserIds.includes(item.label);
        }),
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-12",
      validate: [required],
    },
    {
      name: "h3Outreach",
      label: "Outreach",
      type: "h3",
      hide: loggedInAs("AD"),
      className: "color-1 mt-4 mb-3 text-uppercase col-12",
    },
    {
      name: "ownerOccupiedFilter",
      label: "Owner Occupied",
      type: "boolean",
      onChange: (e) => {
		//console.log('Owner Occupied', e);
        //setPanel(e ? 2 : 1);
      },
      hide: loggedInAs("AD"),
      disable: !templateList.length,
      viewMode: !templateList.length,
      tooltip: !templateList.length
        ? "Create email, SMS and mailer templates in the templates section to turn on"
        : "Switch it on and set templates to automatically filter owner occupired prospect from the realtor and homeowner as the listing status changes",
      formGroupClass: "col-7",
    },
    {
      name: "automaticProspecting",
      label: "Automatic Prospecting",
      type: "boolean",
      onChange: (e) => {
        setPanel(e ? 2 : 1);
        console.log(e);
        if (e) {
			//show ownerOccupiedFilter
		} else {
			//hide ownerOccupiedFilter and set its value to false;
		}
        //dispatch({ error: null });
      },
      hide: loggedInAs("AD"),
      disable: !templateList.length,
      viewMode: !templateList.length,
      tooltip: !templateList.length
        ? "Create email, SMS and mailer templates in the templates section to turn on"
        : "Switch it on and set templates to automatically prospect to the realtor and homeowner as the listing status changes",
      formGroupClass: "col-8",
    },
  ];
};
