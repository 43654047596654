import { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, getFormValues, isDirty, reduxForm } from "redux-form";
import "./UserCreate.scss";

import { userTypes } from "../../../constant/userManagement";
import {
  createUser,
  editUser,
  getCompanyNames,
  getRemainingSeats,
  getUser,
  toggleMainLoader,
} from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import FormGroup from "../../../utils/formGroup";
import { successMessage } from "../../../utils/successMessage";
import { formFields } from "./helper";

const CreateUser = ({
  handleSubmit,
  createUser,
  editUser,
  onCreate = null,
  onEdit = null,
  onCancel = null,
  userDetails,
  initialValues,
  selectedUser,
  toggleMainLoader,
  profileEdit = false,
  formValues,
  dirtyForm,
  getCompanyNames,
  getRemainingSeats,
  remainingSeats,
  companyNameList: companyNames,
  isLicenseReallocation = false,
}) => {
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);

  useEffect(() => {
    userDetails && userDetails.userId && getRemainingSeats(userDetails.userId);
  }, [getRemainingSeats, userDetails]);

  useEffect(() => {
    setEditMode(initialValues && initialValues.userId ? true : false);
  }, [initialValues]);

  useEffect(() => {
    if (isLicenseReallocation && formValues && initialValues) {
      setEmailChanged(formValues.emailAddress !== initialValues.emailAddress);
    }
  }, [formValues, initialValues, isLicenseReallocation]);

  const fetchCompanyNames = useCallback(
    (event) => {
      event &&
        event.value === userTypes.BO &&
        getCompanyNames(userDetails.userId);
    },
    [getCompanyNames, userDetails]
  );

  const submitForm = (values) => {
    toggleMainLoader(true);
    const successHandler = (event) => {
      editMode && onEdit && onEdit();
      onCreate && onCreate({ success: true });
      toggleMainLoader(false);
      successMessage(editMode ? 2002 : 2001);
    };
    const errorHandler = (event) => {
      errorMessage(event, event.error?.details);
      toggleMainLoader(false);
    };
    const userType =
      selectedUser && selectedUser.userType === userTypes.SM
        ? userTypes.BO
        : userDetails.userType === userTypes.AD
        ? userTypes.CA
        : userDetails.userType === userTypes.SM
        ? userTypes.BO
        : userTypes.SM;

    const data = {
      isActive: true,
      companyName: values.companyNameObj
        ? values.companyNameObj.label
        : userDetails.companyName,
      managerId: values.companyNameObj
        ? values.companyNameObj.value
        : userType !== userTypes.AD && selectedUser
        ? selectedUser.userId
        : userDetails
        ? userDetails.userId
        : 0,
      ...values,
      userName:
        initialValues && initialValues.userName
          ? initialValues.userName
          : values.emailAddress,
      userType: values.userType ? values.userType.value : userType,
      fileExtension: ".jpeg",
      uploadedFile64: values.uploadedFile64
        ? values.uploadedFile64[0].base64.replace("data:image/jpeg;base64,", "")
        : null,
      isLicenseReallocation: isLicenseReallocation || false,
    };

    if (!isLicenseReallocation) {
      delete data.licenseReallocationReason;
    }

    const noChanges = () => {
      errorMessage("NO_CHANGE");
      onCancel();
    };

    editMode
      ? dirtyForm
        ? editUser(data, successHandler, errorHandler)
        : noChanges()
      : createUser(
          {
            ...data,
            password: "1234!Qwe",
            accessToTemplates:
              data.accessToTemplates || userDetails.userType === userTypes.AD
                ? true
                : false,
          },
          successHandler,
          errorHandler
        );
  };

  return (
    <React.Fragment>
      <h2 className="mb-2">
        {isLicenseReallocation
          ? "Reallocate License"
          : profileEdit
          ? "Edit Profile"
          : `${editMode ? "Edit" : "Add"} User`}
      </h2>
      <form className="create-user-form" onSubmit={handleSubmit(submitForm)}>
        <div className="form-container">
          {userDetails.userType !== userTypes.AD &&
          !profileEdit &&
          !editMode ? (
            <div className="mb-3">
              <div className="text-md">
                <span className="small text-gray">Remaining seats:</span>{" "}
                <span className="text-primary">
                  {remainingSeats <= 0 ? 0 : remainingSeats}
                </span>
              </div>
              {remainingSeats <= 0 ? (
                <div className="text-sm mt-1">
                  You have no seats remaining to add user!
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="row">
            {formFields({
              setError,
              createMode: !editMode,
              profileEdit,
              userDetails,
              formValues,
              companyNames,
              fetchCompanyNames,
              isLicenseReallocation,
            }).map((item, index) => {
              return item.hide ? null : (
                <Field key={index} {...item} component={FormGroup} />
              );
            })}
          </div>
        </div>
        <div className="form-footer">
          <button
            className="btn btn-default mt-3 mr-2"
            type="button"
            onClick={() => onCancel && onCancel()}>
            Cancel
          </button>
          <button
            disabled={
              (userDetails.userType !== userTypes.AD &&
                !editMode &&
                remainingSeats <= 0) ||
              (isLicenseReallocation &&
                (!emailChanged || !formValues?.licenseReallocationReason))
            }
            className="btn btn-primary mt-3"
            type="submit">
            Submit
          </button>
          {error && (
            <div className="error small text-danger text-center mt-3">
              {error}
            </div>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  userDetails: get(state, "Auth.userDetails", {}),
  formValues: getFormValues("CreateUserForm")(state),
  dirtyForm: isDirty("CreateUserForm")(state),
  companyNameList: get(state, "User.companyNameList", []),
  remainingSeats: get(state, "User.remainingSeats", 0),
});

const mapDispatchToProps = {
  createUser,
  editUser,
  getUser,
  toggleMainLoader,
  getCompanyNames,
  getRemainingSeats,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "CreateUserForm",
    enableReinitialize: true,
  })(CreateUser)
);
