import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { get, min } from "lodash";
import "./index.scss";
import { reduxForm, getFormValues, change } from "redux-form";

import {
	toggleMainLoader,
	getSettings,
	getSettingsNotificationEventTypes,
	getSettingsEmailAccountTypes,
	getSettingsAPIProviders,
	getSettingsAdminList,
	getLastSyncInfo,
	getDataUsageCompanyList,
} from "../../store/actions";
import { userTypes } from "../../constant/userManagement";
import SidePanel from "../../utils/sidePanel";
import Confirm from "../../utils/confirmDelete";
import CreateAdmin from "./createAdmin";
import SettingsForm from "./settingsForm";
import { resetLoadingStates } from "../../store/actions/settingsActions";

const initialStates = {
	userId: null,
	userType: null,
	userSettings: null,

	error: null,

	OptionsAPIProviders: [],
	OptionsEmailAccountTypes: [],

	showCreateUserPanel: false,
	showOtherWarning: false,
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const Settings = ({
	history,
	toggleMainLoader,
	userDetails,
	getSettings,
	getSettingsNotificationEventTypes,
	getSettingsEmailAccountTypes,
	getSettingsAPIProviders,
	getSettingsAdminList,
	emailAccountTypes,
	APIProviders,
	getLastSyncInfo,
	getDataUsageCompanyList,
	resetLoadingStates,
}) => {
	useEffect(() => {
		userDetails &&
			dispatch({
				userId: userDetails.userId,
				userType: userDetails.userType,
			});
	}, [userDetails]);

	useEffect(() => {
		const successHandler = (e) => {
			const { result } = e;
			const apiProviderId = APIProviders.filter(
				(item) =>
					item.apiProviderId === result.apiSettings.apiProviderId
			).map((item) => {
				return {
					...item,
					value: item.apiProviderId,
					label: item.apiName,
				};
			});
			const emailAccountTypeId = emailAccountTypes
				.filter(
					(item) =>
						item.emailAccountTypeId ===
						result.emailSettings.emailAccountTypeId
				)
				.map((item) => {
					return {
						...item,
						value: item.emailAccountTypeId,
						label: item.emailAccountName,
					};
				});
			const userSettings = {
				...result,
				apiSettings: {
					...result.apiSettings,
					apiProviderId:
						apiProviderId && apiProviderId.length
							? apiProviderId[0]
							: null,
				},
				emailSettings: {
					...result.emailSettings,
					emailAccountTypeId:
						emailAccountTypeId && emailAccountTypeId.length
							? emailAccountTypeId[0]
							: 0,
				},
			};
			dispatch({ userSettings });
		};
		APIProviders &&
			APIProviders.length &&
			emailAccountTypes &&
			emailAccountTypes.length &&
			getSettings(successHandler);
	}, [getSettings, APIProviders, emailAccountTypes]);

	useEffect(() => {
		userDetails.userType === userTypes.AD && getSettingsAdminList();
		getSettingsNotificationEventTypes();
		getSettingsEmailAccountTypes();
		getSettingsAPIProviders();
	}, [
		getSettingsAdminList,
		getSettingsNotificationEventTypes,
		getSettingsEmailAccountTypes,
		getSettingsAPIProviders,
		userDetails,
	]);

	useEffect(() => {
		userDetails.userType === userTypes.AD && getLastSyncInfo();
		userDetails.userType === userTypes.AD && getDataUsageCompanyList();
	}, [userDetails, getLastSyncInfo, getDataUsageCompanyList]);

	const [state, dispatch] = useReducer(reducer, initialStates);
	const {
		userId,
		userType,
		showCreateUserPanel,
		showOtherWarning,
		userSettings,
		OptionsEmailAccountTypes,
		OptionsAPIProviders,
	} = state;

	useEffect(() => {
		APIProviders &&
			APIProviders.length &&
			dispatch({
				OptionsAPIProviders: APIProviders.map((item) => {
					return {
						...item,
						label: item.apiName,
						value: item.apiProviderId,
					};
				}),
			});
	}, [APIProviders]);

	useEffect(() => {
		emailAccountTypes &&
			emailAccountTypes.length &&
			dispatch({
				OptionsEmailAccountTypes: emailAccountTypes.map((item) => {
					return {
						...item,
						label: item.emailAccountName,
						value: item.emailAccountTypeId,
					};
				}),
			});
	}, [emailAccountTypes]);

	const onCreate = () => {
		userDetails.userType === userTypes.AD && getSettingsAdminList();
		toggleMainLoader(false);
		dispatch({ showCreateUserPanel: false });
	};

	const onCancel = () => {
		toggleMainLoader(false);
		dispatch({ showCreateUserPanel: false });
	};

	useEffect(() => {
		return () => {
			resetLoadingStates();
		};
	}, []);

	return (
		<section className="moovsoon-page-container">
			<div className="moovsoon-page-body">
				{userId ? (
					<SettingsForm
						{...{
							history,
							userDetails,
							userSettings,
							userType,
							OptionsEmailAccountTypes,
							OptionsAPIProviders,
							initialValues: userSettings,
							createAdminCall: () =>
								dispatch({ showCreateUserPanel: true }),
						}}
					/>
				) : null}
			</div>
			<SidePanel
				show={showCreateUserPanel}
				onHide={onCancel}
				component={CreateAdmin}
				componentProps={{
					onCreate,
					onCancel,
				}}
			/>
			<Confirm
				message={"Contact MoovSoon admin. You may incur extra charges"}
				onYes={() => dispatch({ showOtherWarning: false })}
				onNo={() => dispatch({ showOtherWarning: false })}
				show={showOtherWarning}
			/>
		</section>
	);
};

const mapStateToProps = (state) => {
	const { Settings } = state;
	return {
		userSettings: get(Settings, "userSettings", null),
		notificationEventTypes: get(Settings, "notificationEventTypes", null),
		emailAccountTypes: get(Settings, "emailAccountTypes", null),
		APIProviders: get(Settings, "APIProviders", null),
		adminList: get(Settings, "adminList", null),
		formValues: getFormValues("SettingsForm")(state),
	};
};

const mapDispatchToProps = {
	toggleMainLoader,
	getSettings,
	getSettingsNotificationEventTypes,
	getSettingsEmailAccountTypes,
	getSettingsAPIProviders,
	getSettingsAdminList,
	change,
	getLastSyncInfo,
	getDataUsageCompanyList,
	resetLoadingStates,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "SettingsForm",
		enableReinitialize: true,
	})(Settings)
);
