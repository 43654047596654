import React, { useCallback, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { NotificationContainer } from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./App.scss";

import { userTypes } from "./constant/userManagement";
import Header from "./partials/header/Header";
import NavBar from "./partials/navBar/NavBar";
import { MainRouter, SessionRouter } from "./routers";
import {
  getUser,
  getUserTypes,
  logoutApp,
  resetField,
  toggleMainLoader,
} from "./store/actions";
import { errorMessage } from "./utils/errorMessage";
import Preloader from "./utils/preloader";
import PreloaderInner from "./utils/preloaderinner";
import { successMessage } from "./utils/successMessage";

import { useClerk, useUser } from "@clerk/clerk-react";

const LSVariable = process.env.REACT_APP_LS_VAR;

export const App = ({
  history,
  location,
  mainPreloader,
  toggleMainLoader,
  resetField,
  getUser,
  logoutApp,
  accessToken,
  getUserTypes,
  userDetails,
}) => {
  const { isSignedIn, user } = useUser();
  const { signOut } = useClerk();

  const setLocalStorage = useCallback(() => {
    const getLSVariable = localStorage.getItem(LSVariable);
    const userData = getLSVariable ? JSON.parse(getLSVariable) : null;

    if (!userData) {
      console.log("setLocalStorage", "userData is empty");
      history.push("/signin");
    }
  }, [history]);

  useEffect(() => {
    window.addEventListener("storage", () => {
      // When local storage changes, dump the list to
      // the console.
      setLocalStorage();
    });
  }, [setLocalStorage]);

  useEffect(() => {
    toggleMainLoader(false);
  }, [toggleMainLoader]);

  const ifNotAuthorized = useCallback(() => {
    localStorage.removeItem(LSVariable);
    history.push("/");
    toggleMainLoader(false);
  }, [history, toggleMainLoader]);

  useEffect(() => {
    const getLSVariable = localStorage.getItem(LSVariable);
    const userData = getLSVariable ? JSON.parse(getLSVariable) : null;
    const getUserDetails = (userData) => {
      const successHandler = (event) => {
        const userDetails = event.result;
        userDetails &&
          resetField("Auth.userDetails", userDetails, () =>
            toggleMainLoader(false)
          );
      };
      const errorHandler = (event) => {
        errorMessage(event);
        ifNotAuthorized();
      };
      userData.userId &&
        getUser(
          { userId: userData.userId, type: "SESSION" },
          successHandler,
          errorHandler,
          true
        );
      userData.userType !== userTypes.BO && getUserTypes();
    };
    if (getLSVariable && accessToken) {
      userData && getUserDetails(accessToken ? accessToken : userData);
    }
    if (getLSVariable && !accessToken) {
      userData && resetField("Auth.accessToken", userData);
    }
  }, [
    toggleMainLoader,
    resetField,
    getUser,
    accessToken,
    getUserTypes,
    ifNotAuthorized,
  ]);

  const logout = () => {
    const successHandler = () => {
      successMessage(1004);
      localStorage.removeItem(LSVariable);

      if (isSignedIn && user) {
        signOut();
      }

      history.push("/");

      toggleMainLoader(false);
    };
    logoutApp(successHandler);
  };

  const currentKey = location.pathname; // .split("/")[1] || "/";

  const desideRouter = () => {
    const getLSVariable = localStorage.getItem(LSVariable);
    const userData = getLSVariable ? JSON.parse(getLSVariable) : null;
    if (userData) {
      return (
        <div className="moovsoon-wrap">
          <NavBar userData={userDetails} />
          <div className="moovsoon-container">
            <Scrollbars>
              <div className="d-flex flex-column mih-100">
                <Header logout={logout} />
                <TransitionGroup className="router-transition-wrap flex-fill">
                  <CSSTransition
                    key={currentKey}
                    timeout={{ enter: 50, exit: 900 }}
                    classNames={"transition-wrap"}>
                    <div className="router-transition-container">
                      {userDetails && userDetails.userId ? (
                        <MainRouter
                          userData={userData}
                          location={location}
                          userDetails={userDetails}
                        />
                      ) : null}
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </Scrollbars>
          </div>
        </div>
      );
    }
    return (
      <TransitionGroup className="router-transition-wrap moovsoon-session-wrap">
        <CSSTransition
          key={currentKey}
          timeout={{ enter: 50, exit: 900 }}
          classNames={"transition-wrap"}>
          <div className="router-transition-container moovsoon-session-container">
            <SessionRouter location={location} />
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  };

  return (
    <div className="moovsoon-app">
      <Preloader show={mainPreloader} />
      <PreloaderInner />
      <NotificationContainer />
      {desideRouter()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    mainPreloader: state.UI.mainPreloader,
    accessToken: state.Auth.accessToken,
    userDetails: state.Auth.userDetails,
  };
};

const mapDispatchToProps = {
  toggleMainLoader,
  resetField,
  getUser,
  logoutApp,
  getUserTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
