import { get } from "lodash";
import React, { useReducer } from "react";
import { connect } from "react-redux";
import { change, Field, getFormValues, isDirty, reduxForm } from "redux-form";
import userPic from "../../assets/user.png";
import "./index.scss";

import { userTypes } from "../../constant/userManagement";
import {
  getCompanyDataUsage,
  saveSettings,
  settingsToggleMainLoader,
  testApiConnection,
  toggleMainLoader,
} from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import FormGroup from "../../utils/formGroup";
import { successMessage } from "../../utils/successMessage";
import APISettings from "./apiSettings";
import DataSource from "./datasource";
import EmailSettings from "./emailSettings";
import { formFieldsFn } from "./helper";
import Notifications from "./notifications";
import TemplateSettings from "./templateSettings";

const initialStates = {
  userId: null,

  error: null,

  showOtherWarning: false,
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

const SettingsForm = ({
  history,
  userDetails,
  toggleMainLoader,
  saveSettings,
  userSettings,
  notificationEventTypes,
  OptionsEmailAccountTypes,
  OptionsAPIProviders,
  adminList,
  handleSubmit,
  formValues,
  initialValues,
  change,
  dirtyForm,
  createAdminCall,
  lastSyncInfo,
  dataUsageCompanyList,
  getCompanyDataUsage,
  testApiConnection,
  settingsToggleMainLoader,
  loadingStates
}) => {
  const loggedInAs = (type) => {
    return userDetails.userType === userTypes[type];
  };

  const [state, dispatch] = useReducer(reducer, initialStates);
  const { userId, error } = state;

  const noChanges = () => {
    errorMessage("NO_CHANGE");
    toggleMainLoader(false);
  };

  const submitForm = (values) => {
    if (dirtyForm) {
      toggleMainLoader(true);
      const successHandler = (event) => {
        successMessage(userSettings.userId ? 5002 : 5001);
        toggleMainLoader(false);
      };
      const errorHandler = (event) => {
        errorMessage(event);
        toggleMainLoader(false);
      };
      const apiParams = {
        ...values,
        templateSettings: {
          ...values.templateSettings,
          base64FileString:
            values &&
              values.templateSettings &&
              values.templateSettings.base64FileString &&
              Array.isArray(values.templateSettings.base64FileString)
              ? values.templateSettings.base64FileString[0].base64.replace(
                "data:image/jpeg;base64,",
                ""
              )
              : null,
          fileExtention: ".jpeg",
          fileContentType: "image/jpeg",
        },
        apiSettings: {
          ...values.apiSettings,
          apiProviderId:
            values &&
              values.apiSettings &&
              values.apiSettings.apiProviderId &&
              isNaN(values.apiSettings.apiProviderId)
              ? values.apiSettings.apiProviderId.value
              : values.apiSettings.apiProviderId,
        },
        emailSettings: {
          ...values.emailSettings,
          password:
            values?.emailSettings.emailAccountTypeId.value === 4
              ? ""
              : values.emailSettings.password,
          emailAccountTypeId:
            values &&
              values.emailSettings &&
              values.emailSettings.emailAccountTypeId &&
              isNaN(values.emailSettings.emailAccountTypeId)
              ? values.emailSettings.emailAccountTypeId.value
              : values.emailSettings.emailAccountTypeId,
        },
      };
      saveSettings(
        { createMode: !userSettings.userId, ...apiParams },
        successHandler,
        errorHandler
      );
    } else {
      noChanges();
    }
  };

  const hideOnMockID =
    formValues &&
    formValues.emailSettings &&
    formValues.emailSettings.emailAccountTypeId &&
    formValues.emailSettings.emailAccountTypeId.value === 0;

  const formFields = formFieldsFn({
    dispatch,
    formValues,
    OptionsAPIProviders,
    OptionsEmailAccountTypes,
    notificationEventTypes,
    change,
    userId,
    initialValues,
    hideOnMockID,
  });

  const testApiConnectionAction = (fields, formValues) => {
    formValues &&
      formValues.emailSettings &&
      ((formValues.emailSettings.userName &&
        formValues.emailSettings.password) ||
        (formValues.emailSettings.userName &&
          formValues.emailSettings.clientSecretValue &&
          formValues.emailSettings.clientId &&
          formValues.emailSettings.appTenantId)) &&
      settingsToggleMainLoader(true) &&
      testApiConnection(
        {
          emailAccountTypeId: formValues.emailSettings.emailAccountTypeId.value,
          appPassword: formValues.emailSettings.password,
          clientSecretValue: formValues.emailSettings.clientSecretValue,
          emailAddress: formValues.emailSettings.userName,
          clientId: formValues.emailSettings.clientId,
          tenantId: formValues.emailSettings.appTenantId,
        },
        (result) => {
          settingsToggleMainLoader(false);
          successMessage(5005);
          console.log("settingsForms-passed", result);
        },
        (result) => {
          settingsToggleMainLoader(false);
          errorMessage(result);
          console.log("settingsForms-failed", result);
        }
      );
  };

  const renderAdmins = () => {
    return adminList ? (
      <div className="mb-4">
        <h2 className="mb-2">Super Admins</h2>
        <div className="row super-admin-list">
          {adminList.map((admin, index) => (
            <div key={index} className="col-3 mb-3">
              <div className="card card-primary p-3 text-center">
                <span className="mx-auto mb-2 btn-cell btn-default btn-xl pointer-none">
                  <img src={userPic} alt="User" />
                </span>
                <div>
                  <strong>{admin.fullName}</strong>
                </div>
                <div className="mb-2">{admin.emailAddress}</div>
                <div className="text-success d-flex align-items-center justify-content-center">
                  <i className="icon icon-badge-check mr-1 text-md"></i>{" "}
                  {admin.isActive ? "Active" : "Inactve"}
                </div>
              </div>
            </div>
          ))}
          <div className="col-3 mb-3">
            <div
              className="add-new card card-primary p-3 d-flex align-items-center justify-content-center pointer"
              onClick={createAdminCall}>
              <div className="text-primary align-self-center">
                Add Super Admin
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  };

  return (
    <>
      <form className="user-settings-form" onSubmit={handleSubmit(submitForm)}>
        <Field component={FormGroup} {...formFields.userId} />
        {loggedInAs("AD") ? (
          <>
            <DataSource
              isCompanyListLoading={loadingStates.companyList}
              isAPILoading={loadingStates.apiProviders}
              lastSyncInfo={lastSyncInfo}
              dataUsageCompanyList={dataUsageCompanyList}
              getCompanyDataUsage={getCompanyDataUsage}
              formValues={formValues}
            />

            {renderAdmins()}
          </>
        ) : null}
        {loggedInAs("CA") ? (
          <>
            <h2 className="mb-2">Company Settings</h2>
            <div className="card card-primary p-4 mb-4">
              <APISettings
                userSettings={userSettings}
                fields={formFields.apiSettings}
              />
              <TemplateSettings
                userSettings={userSettings}
                fields={formFields.templateSettings}
                isUserSettingsLoading={loadingStates.userSettings}
              />
            </div>
          </>
        ) : null}
        <h2 className="mb-2">Personal Settings</h2>
        <div className="card card-primary p-4">
          {loggedInAs("AD") ? null : (
            <EmailSettings
              isUserSettingsLoading={loadingStates.userSettings}
              emailAccountTypes={OptionsEmailAccountTypes}
              fields={formFields.emailSettings}
              formValues={formValues}
              hideOnMockID={hideOnMockID}
              testApiConnectionAction={testApiConnectionAction}
            />
          )}
          {notificationEventTypes ? (
            <Notifications
              userSettings={userSettings}
              fields={formFields.notificationSettings}
            />
          ) : null}
        </div>

        <div className="form-footer">
          <button
            className="btn btn-default mt-3 mr-2"
            type="button"
            onClick={() => history.push("/")}>
            Cancel
          </button>

          <button className="btn btn-primary mt-3" type="submit">
            Save
          </button>

          {error && (
            <div className="error small text-danger text-center mt-3">
              {error}
            </div>
          )}
        </div>
      </form>
      {loggedInAs("CA") && userDetails.licenseReallocationHistory ? (
        <>
          <h2 className="mb-2 mt-4">License Reallocation History</h2>
          <div className="card card-primary p-4 mb-4">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Previous User</th>
                    <th>New User</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(userDetails.licenseReallocationHistory).map(
                    (history, index) => (
                      <tr key={index}>
                        <td>
                          {new Date(history.dateOfReallocation)
                            .toLocaleString("en-US", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })
                            .replace(",", "")}
                        </td>
                        <td>
                          <div>{history.oldUserName}</div>
                        </td>
                        <td>
                          <div>{history.newUserName}</div>
                        </td>
                        <td>{history.reason}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const { Settings } = state;
  return {
    userSettings: get(Settings, "userSettings", null),
    notificationEventTypes: get(Settings, "notificationEventTypes", null),
    emailAccountTypes: get(Settings, "emailAccountTypes", null),
    APIProviders: get(Settings, "APIProviders", null),
    adminList: get(Settings, "adminList", null),
    lastSyncInfo: get(Settings, "lastSyncInfo", null),
    dataUsageCompanyList: get(Settings, "dataUsageCompanyList", null),
    loadingStates: get(Settings, "loadingStates", {}),
    formValues: getFormValues("SettingsForm")(state),
    dirtyForm: isDirty("SettingsForm")(state),
  };
};

const mapDispatchToProps = {
  toggleMainLoader,
  saveSettings,
  change,
  getCompanyDataUsage,
  testApiConnection,
  settingsToggleMainLoader,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "SettingsForm",
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch) => {
      setTimeout(() => {
        const allErrors = [...document.querySelectorAll(".form-group.error")];
        allErrors &&
          allErrors.length &&
          allErrors[0].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
      });
    },
  })(SettingsForm)
);
