import { REPORTS } from "../types";
import { mockGUID } from "../../constant/global";

const initialState = {
	zipCodes: [],
	realtor: [],
	status: [],
	convsource: [],
	movetype: [],
	users: [],
	columnList: [],
	loading: {
		zipCodes: true,
		realtor: true,
		status: true,
		convsource: true,
		movetype: true,
		users: true,
		columnList: true,
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case REPORTS.GET_REPORTS_ZIPCODE_LIST:
			return {
				...state,
				zipCodes: action.payload ? action.payload.result : [],
				loading: {
					...state.loading,
					zipCodes: false
				}
			};
		case REPORTS.GET_REPORTS_REALTOR_LIST:
			return {
				...state,
				realtor: (() => {
					const realtor = action.payload
						? [
							{
								value: mockGUID,
								label: "Select",
							},
							...action.payload.result,
						]
						: [];

					return realtor;
				})(),
				loading: {
					...state.loading,
					realtor: false
				}
			};
		case REPORTS.GET_REPORTS_STATUS_LIST:
			return {
				...state,
				status: (() => {
					const status = action.payload
						? [
							{
								value: mockGUID,
								label: "Select",
							},
							...action.payload.result,
						]
						: [];

					return status;
				})(),
				loading: {
					...state.loading,
					status: false
				}
			};
		case REPORTS.GET_REPORTS_SOURCE_LIST:
			return {
				...state,
				convsource: (() => {
					const convsource = action.payload
						? [
							{
								value: mockGUID,
								label: "Select",
							},
							...action.payload.result,
						]
						: [];

					return convsource;
				})(),
				loading: {
					...state.loading,
					convsource: false
				}
			};
		case REPORTS.GET_REPORTS_MOVE_TYPE_LIST:
			return {
				...state,
				movetype: (() => {
					const movetype = action.payload
						? [
							{
								value: mockGUID,
								label: "Select",
							},
							...action.payload.result,
						]
						: [];

					return movetype;
				})(),
				loading: {
					...state.loading,
					movetype: false
				}
			};
		case REPORTS.GET_REPORTS_USER_LIST:
			return {
				...state,
				users: (() => {
					const users = action.payload
						? [
							{
								value: mockGUID,
								label: "Select",
							},
							...action.payload.result,
						]
						: [];

					return users;
				})(),
				loading: {
					...state.loading,
					users: false
				}
			};
		case REPORTS.GET_REPORTS_COLUMN_LIST:
			return {
				...state,
				columnList: action.payload ? action.payload.result : [],
				loading: {
					...state.loading,
					columnList: false
				}
			};
		default:
			return state;
	}
};
