export const COMMON = {
  MAIN_PRELOADER: "MAIN_PRELOADER",
  RESET_FIELD: "RESET_FIELD",
  LOGOUT_APP: "LOGOUT_APP",
};

export const SESSION = {
  GET_USER_DETAILS: "GET_USER_DETAILS",
  SEND_LOGIN_CREDENTIALS: "SEND_LOGIN_CREDENTIALS",
  UPDATE_LOGIN_CREDENTIALS: "UPDATE_LOGIN_CREDENTIALS",
  SEND_FORGOT_PASSWORD_CREDENTIALS: "SEND_FORGOT_PASSWORD_CREDENTIALS",
  SEND_REGISTER_NOW_CREDENTIALS: "SEND_REGISTER_NOW_CREDENTIALS",
  SEND_RESET_PASSWORD_CREDENTIALS: "SEND_RESET_PASSWORD_CREDENTIALS",
  EIDT_USER_DETAILS: "EIDT_USER_DETAILS",
};

export const USER = {
  CREATE_USER: "CREATE_USER",
  GET_USERS: "GET_USERS",
  GET_SELECTED_USER: "GET_SELECTED_USER",
  GET_EDITING_USER: "GET_EDITING_USER",
  EIDT_USER: "EIDT_USER",
  GET_USER_TYPES: "GET_USER_TYPES",
  GET_COMPANY_NAMES: "GET_COMPANY_NAMES",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  DELETE_USER: "DELETE_USER",
  GET_REMAINING_SEATS: "GET_REMAINING_SEATS",
};

export const MARKET = {
  CREATE_MARKET: "CREATE_MARKET",
  GET_MARKET_ZIPCODES: "GET_MARKET_ZIPCODES",
  GET_MARKET_COMPANIES: "GET_MARKET_COMPANIES",
  CLEAR_MARKET_COMPANIES: "CLEAR_MARKET_COMPANIES",
  GET_COMPANY_MARKETS: "GET_COMPANY_MARKETS",
  GET_COMPANY_SUB_MARKETS: "GET_COMPANY_SUB_MARKETS",
  GET_MARKETS: "GET_MARKETS",
  UPDATE_MARKET: "UPDATE_MARKET",
  GET_MARKET_DETAILS: "GET_MARKET_DETAILS",
  DELETE_MARKET: "DELETE_MARKET",
  SUB_MARKET_CHECK_AVAILABILITY: "SUB_MARKET_CHECK_AVAILABILITY",
  GET_TEMPLATE_PROSPECTING_EVENTS: "GET_TEMPLATE_PROSPECTING_EVENTS",
  GET_ALL_MARKET_TEMPLATES: "GET_ALL_MARKET_TEMPLATES",
  SET_SUB_MARKET_PROSPECTING: "SET_SUB_MARKET_PROSPECTING",
  MARKET_PRELOADER: "MARKET_PRELOADER",
};

export const SETTINGS = {
  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  GET_SETTINGS: "GET_SETTINGS",
  GET_SETTINGS_NOTIFICATION_EVENT_TYPES:
    "GET_SETTINGS_NOTIFICATION_EVENT_TYPES",
  GET_SETTINGS_EMAIL_ACCOUNT_TYPES: "GET_SETTINGS_EMAIL_ACCOUNT_TYPES",
  GET_SETTINGS_API_PROVIDERS: "GET_SETTINGS_API_PROVIDERS",
  GET_SETTINGS_ADMIN_LIST: "GET_SETTINGS_ADMIN_LIST",
  GET_LAST_SYNC_INFO: "GET_LAST_SYNC_INFO",
  GET_DATA_USAGE_COMPANY_LIST: "GET_DATA_USAGE_COMPANY_LIST",
  GET_COMPANY_API_USAGE: "GET_COMPANY_API_USAGE",
  TEST_API: "TEST_API",
  SETTINGS_PRELOADER: "SETTINGS_PRELOADER",
  RESET_LOADING_STATES: "RESET_LOADING_STATES",
};

export const REALTOR = {
  GET_REALTORS: "GET_REALTORS",
  GET_REALTOR_DETAILS: "GET_REALTOR_DETAILS",
  GET_REALTOR_LISTING_COVERAGE: "GET_REALTOR_LISTING_COVERAGE",
  GET_REALTOR_MARKET_LIST: "GET_REALTOR_MARKET_LIST",
  SET_FILTER: "SET_FILTER",
  GET_REALTOR_EDIT_DATA: "GET_REALTOR_EDIT_DATA",
  UPDATE_REALTOR_DATA: "UPDATE_REALTOR_DATA",
  REALTOR_PRELOADER: "REALTOR_PRELOADER",
};

export const TEMPLATE = {
  GET_TEMPLATE_LIST: "GET_TEMPLATE_LIST",
  CREATE_TEMPLATE: "CREATE_TEMPLATE",
  UPDATE_TEMPLATE: "UPDATE_TEMPLATE",
  GET_TEMPLATE_DETAILS: "GET_TEMPLATE_DETAILS",
  GET_TEMPLATE_COMPANY_DETAILS: "GET_TEMPLATE_COMPANY_DETAILS",
  GET_TEMPLATE_PROSPECTING_EVENTS: "GET_TEMPLATE_PROSPECTING_EVENTS",
  DELETE_TEMPLATE: "DELETE_TEMPLATE",
  UPLOAD_POST_CARD_IMAGE: "UPLOAD_POST_CARD_IMAGE",
  GET_MAILER_SIZE: " GET_MAILER_SIZE",
};

//KAN-52: add new property for switch case
export const PROPERTY = {
  GET_PROPERTIES: "GET_PROPERTIES",
  UPDATE_PROPERTY_SETTINGS: "UPDATE_PROPERTY_SETTINGS",
  GET_PROPERTY_ZIPCODES: "GET_PROPERTY_ZIPCODES",
  GET_PROPERTY_STATUSES: "GET_PROPERTY_STATUSES",
  GET_PROPERTY_REALTORS: "GET_PROPERTY_REALTORS",
  GET_PROPERTY_CHILD_MARKETS: "GET_PROPERTY_CHILD_MARKETS",
  GET_PROPERTY_SAVED_FILTERS: "GET_PROPERTY_SAVED_FILTERS",
  CREATE_PROPERTY_SAVED_FILTER: "CREATE_PROPERTY_SAVED_FILTER",
  UPDATE_PROPERTY_STATUS: "UPDATE_PROPERTY_STATUS",
  GET_PROPERTY_DETAILS: "GET_PROPERTY_DETAILS",
  CLEAR_PROPERTY_DETAILS: "CLEAR_PROPERTY_DETAILS",
  GET_PROPERTY_NOTIFICATION_SETTINGS: "GET_PROPERTY_NOTIFICATION_SETTINGS",
  SAVE_NOTIFICATION_SETTINGS: "SAVE_NOTIFICATION_SETTINGS",
  UPDATE_TRACK_CONVERSION: "UPDATE_TRACK_CONVERSION",
  GET_PROPERTY_SOURCE_LIST: "GET_PROPERTY_SOURCE_LIST",
  GET_MOVE_TYPE_LIST: "GET_MOVE_TYPE_LIST",
  GET_PROPERTIES_OWNER_INFO: "GET_PROPERTIES_OWNER_INFO",
  FETCH_PROPERTIES_OWNER_INFO: "FETCH_PROPERTIES_OWNER_INFO",
  GET_TRACK_CONVERSION: "GET_TRACK_CONVERSION",
  SET_PROPERTY_FILTER: "SET_PROPERTY_FILTER",
  SET_PERSIST_FILTER: "SET_PERSIST_FILTER",
  SET_PROPERTY_OWNER_INFO_REQUESTS: "SET-OWNER_INFO_REQUESTS",
  RESET_LOADING_STATES: "RESET_LOADING_STATES"
};

export const PROSPECTING = {
  GET_REALTOR_COMMUNICATION_FEED: "GET_REALTOR_COMMUNICATION_FEED",
  SEND_MANUAL_PROSPECTING: "SEND_MANUAL_PROSPECTING",
  GET_COMMUNICATION_FEED_DETAILS: "GET_COMMUNICATION_FEED_DETAILS",
};

export const NOTIFICATION = {
  GET_IN_APP_NOTIFICATIONS: "GET_IN_APP_NOTIFICATIONS",
  GET_IN_APP_NOTIFICATION_DETAILS: "GET_IN_APP_NOTIFICATION_DETAILS",
};

export const REPORTS = {
  GET_REPORTS_ZIPCODE_LIST: "GET_REPORTS_ZIPCODE_LIST",
  GET_REPORTS_REALTOR_LIST: "GET_REPORTS_REALTOR_LIST",
  GET_REPORTS_STATUS_LIST: "GET_REPORTS_STATUS_LIST",
  GET_REPORTS_SOURCE_LIST: "GET_REPORTS_SOURCE_LIST",
  GET_REPORTS_MOVE_TYPE_LIST: "GET_REPORTS_MOVE_TYPE_LIST",
  GET_REPORTS_USER_LIST: "GET_REPORTS_USER_LIST",
  GET_REPORTS_COLUMN_LIST: "GET_REPORTS_COLUMN_LIST",
  GET_REPORTS_TIMEFRAME_LIST: "GET_REPORTS_TIMEFRAME_LIST",
  PROCESS_REPORT: "PROCESS_REPORT",
  REALTOR_REPORT: "REALTOR_REPORT",
};

export const DASHBOARD = {
  GET_ADMIN_CHART_DATA: "GET_ADMIN_CHART_DATA",
  GET_COMPANY_CHART_DATA: "GET_COMPANY_CHART_DATA",
  GET_PERFORMANCE_HISTORY: "GET_PERFORMANCE_HISTORY",
  GET_MARKET_LIST: "GET_MARKET_LIST",
  GET_MARKET_DETAILS: "GET_MARKET_DETAILS",
  GET_PROPERTIES: "GET_PROPERTIES",
  GET_REALTORS: "GET_REALTORS",
};
