import {
  required,
  email,
  USPhoneNumber,
  normalizePhone,
} from "../../constant/formValidators";

export const formFieldsFn = ({
  dispatch,
  OptionsAPIProviders,
  OptionsEmailAccountTypes,
  notificationEventTypes,
  formValues,
  change,
  userId,
  initialValues,
  hideOnMockID,
}) => {
  const apiSettingsRequiredValidation =
    formValues &&
    formValues.apiSettings &&
    formValues.apiSettings.apiProviderId &&
    formValues.apiSettings.apiProviderId.value === 2;

  const emailSettingsRequiredValidation =
    (formValues &&
      formValues.emailSettings &&
      formValues.emailSettings.emailAccountTypeId &&
      formValues.emailSettings.emailAccountTypeId.value) ||
    (initialValues && initialValues.emailSettings.emailAccountTypeId);

  const emailSettingsUserNameHasValue = formValues &&
    (formValues.emailSettings &&
      formValues.emailSettings.userName &&
      formValues.emailSettings.userName.length > 0 &&
      formValues.emailSettings.password &&
      formValues.emailSettings.password.length > 0);

  const emailSettingsUserNameo365HasValue = formValues &&
    (formValues.emailSettings &&
      formValues.emailSettings.userName &&
      formValues.emailSettings.userName.length > 0 &&
      formValues.emailSettings.clientSecretValue &&
      formValues.emailSettings.clientSecretValue.length > 0);


  return {
    userId: {
      name: `userId`,
      label: null,
      type: "hidden",
      defaultValue: userId,
    },
    apiSettings: {
      apiProviderId: {
        name: "apiSettings.apiProviderId",
        label: "Mailer Account",
        type: "select",
        options: OptionsAPIProviders,
        onChange: (e) => {
          e && e.value === 3
            ? dispatch({ showOtherWarning: true, error: null })
            : dispatch({ showOtherWarning: false, error: null });
          e && e.value === 1 && change("apiSettings.apiKey", "");
        },
        defaultValue:
          formValues && formValues.apiSettings
            ? formValues.apiSettings.apiProviderId
            : [],
        formGroupClass: "col-5",
        validate: [required],
      },
      apiKey: {
        name: "apiSettings.apiKey",
        label: "Account API Key",
        type: "text",
        onChange: () => dispatch({ error: null }),
        formGroupClass: "col-5",
        hide: !apiSettingsRequiredValidation,
        validate: apiSettingsRequiredValidation ? [required] : [],
        maxlength: 500,
      },
    },
    templateSettings: {
      base64FileString: {
        name: "templateSettings.base64FileString",
        label: "Company Logo",
        type: "file",
        multiple: false,
        defaultValue: formValues && formValues.templateSettings?.companyLogoURL,
        onChange: () => dispatch({ error: null }),
      },
      // base64FileString: "string",
      // fileExtention: "string",
      // fileContentType: "string",
      website: {
        name: "templateSettings.website",
        label: "Company Website URL",
        type: "text",
        onChange: () => dispatch({ error: null }),
        validate: [],
      },
      faceBookUrl: {
        name: "templateSettings.faceBookUrl",
        label: "Facebook URL",
        type: "text",
        onChange: () => dispatch({ error: null }),
        validate: [],
        formGroupClass: "col-6",
      },
      youtubeUrl: {
        name: "templateSettings.youtubeUrl",
        label: "Youtube URL",
        type: "text",
        onChange: () => dispatch({ error: null }),
        validate: [],
        formGroupClass: "col-6",
      },
      linkedInUrl: {
        name: "templateSettings.linkedInUrl",
        label: "LinkedIn URL",
        type: "text",
        onChange: () => dispatch({ error: null }),
        validate: [],
        formGroupClass: "col-6",
      },
      twitterUrl: {
        name: "templateSettings.twitterUrl",
        label: "Twitter URL",
        type: "text",
        onChange: () => dispatch({ error: null }),
        validate: [],
        formGroupClass: "col-6",
      },
    },
    emailSettings: {
      emailAccountTypeId: {
        name: "emailSettings.emailAccountTypeId",
        label: "Email Account Type",
        type: "select",
        options: OptionsEmailAccountTypes,
        onChange: () => {
          change("emailSettings.userName", "");
          change("emailSettings.password", "");
          change("emailSettings.clientId", "");
          change("emailSettings.appTenantId", "");
          change("emailSettings.clientSecretValue", "");
          dispatch({ error: null });
        },
        formGroupClass: "col-5",
        defaultValue:
          formValues && formValues.emailSettings
            ? formValues.emailSettings.emailAccountTypeId
            : [],
        validate: [required],
      },
      userName: {
        name: "emailSettings.userName",
        label: "Username",
        type: "text",
        disable: emailSettingsUserNameHasValue || emailSettingsUserNameo365HasValue ? true : false,
        //showPassword: false,
        onChange: () => dispatch({ error: null }),
        formGroupClass: "col-6 pr-0",
        hide: !emailSettingsRequiredValidation || hideOnMockID,
        validate: emailSettingsRequiredValidation ? [required] : [],
      },
      password: {
        name: "emailSettings.password",
        label: "App Password ",
        type: "password",
        onChange: () => dispatch({ error: null }),
        formGroupClass: "col-6 pr-0",
        hide: !emailSettingsRequiredValidation || hideOnMockID,
        validate: emailSettingsRequiredValidation ? [required] : [],
      },
      clientId: {
        name: "emailSettings.clientId",
        label: "Client ID",
        type: "password",
        onChange: () => dispatch({ error: null }),
        formGroupClass: "col-6 pr-0",
        hide: !emailSettingsRequiredValidation || hideOnMockID,
        validate: emailSettingsRequiredValidation ? [required] : [],
      },
      appTenantId: {
        name: "emailSettings.appTenantId",
        label: "Tenant ID",
        type: "password",
        onChange: () => dispatch({ error: null }),
        formGroupClass: "col-6 pr-0",
        hide: !emailSettingsRequiredValidation || hideOnMockID,
        validate: emailSettingsRequiredValidation ? [required] : [],
      },
      clientSecretValue: {
        name: "emailSettings.clientSecretValue",
        label: "Secret Key ",
        type: "password",
        showPassword: false,
        onChange: () => dispatch({ error: null }),
        formGroupClass: "col-6 pr-0",
        hide: !emailSettingsRequiredValidation || hideOnMockID,
        validate: emailSettingsRequiredValidation ? [required] : [],
      },
    },
    notificationSettings:
      notificationEventTypes && notificationEventTypes.length
        ? notificationEventTypes.map((item, index) => {
          const checkbox = (name) => {
            return {
              name: `notificationSettings[${index}][${name}]`,
              label: null,
              type: "checkbox",
              onChange: () => {
                change(
                  `notificationSettings[${index}].notificationEventTypeId`,
                  item.id
                );
                dispatch({ error: null });
              },
              formGroupClass: "col-2 text-center",
              validate: [],
            };
          };
          const renderCheckboxes = ["inApp", "sms", "email"].map((name) =>
            checkbox(name)
          );

          const values = {
            ...item,
            checkBoxes: renderCheckboxes,
            notificationEventTypeId: {
              name: `notificationSettings[${index}].notificationEventTypeId`,
              label: null,
              type: "hidden",
              defaultValue: item.id,
            },
            active: true,
          };

          return values;
        })
        : [],
  };
};

export const createAdminFieldsFn = ({ dispatch }) => {
  return [
    {
      name: "name",
      label: "First Name",
      type: "text",
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-12",
      validate: [required],
    },
    {
      name: "surname",
      label: "Last Name",
      type: "text",
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-12",
      validate: [required],
    },
    {
      name: "emailAddress",
      label: "Email Address",
      type: "email",
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-12",
      validate: [required, email],
    },
    {
      name: "personalPhoneNo",
      label: "Phone Number",
      type: "text",
      onChange: () => dispatch({ error: null }),
      formGroupClass: "col-12",
      validate: [required, USPhoneNumber],
      normalize: normalizePhone,
    },
  ];
};
