import React from "react";
import { propertyStatus } from "../../../constant/propertyManagement";
import { Date } from "../../../utils/formatter";
import { dateFormat } from "../../../constant/global";

const TimeLine = ({ propertyDetails, dataLoaded, isPropertyDetailsLoading }) => {
	const getPropertyStatus = (value) => {
		return (
			propertyDetails &&
			propertyDetails.propertyStatusTimeline &&
			propertyDetails.propertyStatusTimeline.filter((item) => {
				return item.propertyStatus === value;
			})[0]
		);
	};

	const getBlock = (key, value) => {
		const status = getPropertyStatus(value);
		const classValue = status?.isCurrentStatus ? "orange" : "gray";
		const disabledClass = status?.isDisabled ? "icon-disable" : "";
		const lineClassValue = "dark-border-div";
		return propertyDetails && dataLoaded ? (
			<div className="w-25 d-flex flex-row">
				{key === "active" && classValue === "orange" ? (
					<i
						className={`status-iccon ${disabledClass} icon-status-active-orange-png`}
					></i>
				) : (
					<i
						className={`status-iccon ${disabledClass} icon-status-${key}-${classValue}`}
					></i>
				)}

				{key !== "offmarket" ? (
					<div className={`${lineClassValue}`}></div>
				) : null}
			</div>
		) : null;
	};

	const getTextBlock = (value, label) => {
		const status = getPropertyStatus(value);

		if (isPropertyDetailsLoading || !dataLoaded) {
			return <div className="w-25 d-flex flex-row">
				<span className="label-text">{label}</span>
			</div>
		}

		return propertyDetails && dataLoaded ? (
			<div className="w-25 d-flex flex-row">
				<span className="label-text">{label}</span>
				{status?.statusChangeDate ? (
					<span className="pl-2 label-text">
						<Date
							utcTime={status.statusChangeDate}
							format={dateFormat}
						/>
					</span>
				) : null}
			</div>
		) : null;
	};


	const getLoadingBlocks = (key) => {
		return <div className="w-25 d-flex flex-row">
			<i
				className={`status-iccon icon-disable icon-status-${key}-gray`}
			></i>
			{key !== "offmarket" ? (
				<div className="dark-border-div"></div>
			) : null}
		</div>
	}

	if (isPropertyDetailsLoading || !dataLoaded) {
		return (
			<>
				<div className="card d-flex flex-row card-primary pt-2 pl-2 h-50 no-border-shadow">
					{getLoadingBlocks("new")}
					{getLoadingBlocks("active")}
					{getLoadingBlocks("pending")}
					{getLoadingBlocks("offmarket")}
				</div>
				<div className="card d-flex flex-row card-primary pl-2 no-border-shadow">
					{getTextBlock(
						propertyStatus.new.value,
						propertyStatus.new.label
					)}
					{getTextBlock(
						propertyStatus.active.value,
						propertyStatus.active.label
					)}
					{getTextBlock(
						propertyStatus.pending.value,
						propertyStatus.pending.label
					)}
					{getTextBlock(
						propertyStatus.offmarket.value,
						propertyStatus.offmarket.label
					)}
				</div>
			</>
		);
	}

	return (
		<>
			<div className="card d-flex flex-row card-primary pt-2 pl-2 h-50 no-border-shadow">
				{getBlock("new", propertyStatus.new.value)}
				{getBlock("active", propertyStatus.active.value)}
				{getBlock("pending", propertyStatus.pending.value)}
				{getBlock("offmarket", propertyStatus.offmarket.value)}
			</div>
			<div className="card d-flex flex-row card-primary pl-2 no-border-shadow">
				{getTextBlock(
					propertyStatus.new.value,
					propertyStatus.new.label
				)}
				{getTextBlock(
					propertyStatus.active.value,
					propertyStatus.active.label
				)}
				{getTextBlock(
					propertyStatus.pending.value,
					propertyStatus.pending.label
				)}
				{getTextBlock(
					propertyStatus.offmarket.value,
					propertyStatus.offmarket.label
				)}
			</div>
		</>
	);
};
export default TimeLine;
