import { MARKET } from "../types";

const initialState = {
	marketZipcodes: [],
	marketCompanies: [],
	companyMarkets: [],
	companySubMarkets: [],
	marketList: [],
	marketDetails: null,
	loadingStates: {
		companyMarkets: true,
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case MARKET.GET_MARKET_ZIPCODES:
			return {
				...state,
				marketZipcodes: (() => {
					const zipCodes = action.payload
						? action.payload.result
						: [];
					const filtered = zipCodes.reduce(
						(unique, zip) =>
							unique.some((item) => item.label === zip.label)
								? unique
								: [...unique, zip],
						[]
					);
					return filtered;
				})(),
				// marketZipcodes: action.payload ? action.payload.result : []
			};
		case MARKET.GET_MARKET_COMPANIES:
			return {
				...state,
				marketCompanies: action.payload ? action.payload.result : [],
			};
		case MARKET.CLEAR_MARKET_COMPANIES:
			return {
				...state,
				marketCompanies: [],
			};
		case MARKET.GET_COMPANY_MARKETS:
			return {
				...state,
				companyMarkets: action.payload ? action.payload.result : null,
				loadingStates: {
					...state.loadingStates,
					companyMarkets: false,
				},
			};
		case MARKET.GET_COMPANY_SUB_MARKETS:
			return {
				...state,
				companySubMarkets: action.payload
					? action.payload.result
					: null,
			};
		case MARKET.GET_MARKETS:
			return {
				...state,
				marketList: action.payload ? action.payload.result : [],
			};
		case MARKET.GET_MARKET_DETAILS:
			return {
				...state,
				marketDetails: action.payload ? action.payload.result : null,
			};
		case MARKET.SET_SUB_MARKET_PROSPECTING:
			return {
				...state,
				propspectinginitialValues: action.payload
					? action.payload
					: null,
			};
		case MARKET.MARKET_PRELOADER:
			return {
				...state,
				marketPreloader: action.payload,
			};
		default:
			return state;
	}
};
