import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import { Editor } from "@tinymce/tinymce-react";
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { FixedSizeList } from 'react-window';
import { datePickerDateFormat } from "../constant/global";
import { googleFonts } from "../constant/googleFonts";
import ToggleButton from "./toggleButton";
import Tooltip from "./tooltip";
import FileUpload from "./uploadFile";




export const CustomInput = ({ onPaste, ...props }) => (
  <components.Input
    {...props}
    onPaste={onPaste}
  />
);

export const VirtualizedMenuList = ({ children, maxHeight }) => {
  const height = 35;
  const childrenArray = React.Children.toArray(children);

  return (
    <FixedSizeList
      height={Math.min(maxHeight, height * childrenArray.length)}
      itemCount={childrenArray.length}
      itemSize={height}
      style={{ paddingTop: 0, paddingBottom: 0 }}
    >
      {({ index, style }) => (
        <div style={style}>
          {children[index]}
        </div>
      )}
    </FixedSizeList>
  );
};


export const LoadingIndicator = () => (
  <div style={{
    color: '#666',
    padding: '8px',
    textAlign: 'center',
    fontSize: '14px'
  }}>
    Loading options...
  </div>
);

export const NoOptionsMessage = ({ innerProps, children, isLoading }) => {
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <components.NoOptionsMessage {...innerProps}>
      {children}
    </components.NoOptionsMessage>
  );
};

const FormGroup = ({
  input,
  label,
  type,
  defaultValue,
  isOptionDisabled = false,
  options = [],
  formGroupClass = "",
  showPassword = true,
  matchWith = null,
  hide = false,
  multiple = false,
  resizeImage = [150, 150],
  meta: { touched, error, warning },
  className = "",
  render = null,
  info = null,
  numberOnly = false,
  list = [],
  placeholder = null,
  rteData = "Type some text...",
  selectedDate = "",
  placeholderText = "",
  minDate,
  maxDate,
  disable = false,
  viewMode = false,
  formatOptionLabel,
  booleanValue = false,
  tooltip,
  radioLabel,
  customeTag,
  resize = true,
  minWidth = null,
  minHeight = null,
  onFileError = null,
  imageOnly = false,
  maxSize = false,
  maxlength = false,
  disableThumbnail = false,
  formatCreateLabel = "",
  zipRef,
  isLoading = false,
}) => {
  const [toggleShowPassword, setToggleShowPassword] = useState(false);
  const [inputType] = useState(type);
  const [matching, setMatching] = useState(false);
  const [selected, setSelected] = useState(null);
  const [RTEValue, setRTEValue] = useState(rteData);
  useEffect(() => {
    if (
      matchWith &&
      matchWith.formValues &&
      matchWith.formValues[matchWith.field] &&
      input.value
    ) {
      const fieldValue = matchWith.formValues[matchWith.field];
      setMatching(fieldValue === input.value);
    } else {
      setMatching(false);
    }
  }, [matchWith, input]);

  useEffect(() => {
    input && (!input.value || input.value === "") && setSelected([]);
  }, [input]);

  const onSelectChange = (value) => {
    console.log(value)
    input.onChange(value);
    setSelected(value);
  };

  const onSelectKeydown = (event) => {
    const isPasteShortcut =
      (event.ctrlKey || event.metaKey) && event.nativeEvent.key === "v";

    if (isPasteShortcut) {
      return;
    }

    if (event.nativeEvent.key === " ") {
      event.preventDefault();
    }

    const allowedKeys = [
      "Delete",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
    ];

    if (
      (numberOnly && !isNaN(event.nativeEvent.key)) ||
      allowedKeys.includes(event.nativeEvent.key)
    ) {
      return;
    }

    event.preventDefault();
  };



  const onFileChange = (value) => {
    input.onChange(value);
  };
  const onError = (value) => {
    onFileError(value);
  };

  useEffect(() => {
    (type === "rte" || type === "rte1" || type === "rte2") &&
      RTEValue &&
      RTEValue !== input.value &&
      input.onChange(RTEValue);
  }, [RTEValue, input, type]);

  const onRTEChange = (event, editor) => {
    const data = editor.getData() ? editor.getData() : "&nbsp;";
    setRTEValue(data);
  };

  const onRTE1Change = (content, editor) => {
    const data = content ? content : "&nbsp;";
    setRTEValue(data);
  };

  const renderBadges = () =>
    list.map((item, index) => (
      <div className="badge mr-2 mb-2 badge-secondary" key={index}>
        {item.label}
      </div>
    ));


  const handleZipCodePaste = useCallback((event, allowCreate = false) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');

    const zipcodes = pastedText
      .split(/[\s,\n]+/)
      .map(zip => zip.trim())
      .filter(zip => zip.length > 0);

    const currentValues = Array.isArray(input.value) ? input.value : [];
    const existingZips = new Set(currentValues.map(v => v.label));

    const newOptions = zipcodes.map(zip => {
      const existingOption = options.find(opt => opt.label === zip);
      if (existingOption && !existingZips.has(existingOption.label)) {
        return existingOption;
      }
      if (allowCreate) {
        return {
          label: zip,
          value: zip,
          isNew: true
        };
      }
      return null;
    }).filter(Boolean);

    if (newOptions.length > 0) {
      const updatedValue = [...currentValues, ...newOptions];
      onSelectChange(updatedValue);
    }
  }, [input, options, onSelectChange]);

  const sharedSelectStyles = {
    menuList: (base) => ({
      ...base,
      height: '100%',
      maxHeight: 300
    })
  };

  const doughnutStyles = {
    option: (styles, { isSelected }) => ({
      ...styles,
      color: "#000",
      backgroundColor: isSelected ? "#cbdaef" : "transparent",
    })
  };

  const renderInput = () => {
    const labelOrRender = label ? label : render ? render() : null;
    switch (type) {
      case "h3":
        return <h3 className={className}>{labelOrRender}</h3>;
      case "div":
        return <div className={className}>{labelOrRender}</div>;
      case "badges":
        return (
          <div className={`form-group ${className}`}>
            <div className="form-value">{renderBadges()}</div>
            {label ? <span className="form-label">{label}</span> : null}
          </div>
        );
      case "hidden":
        defaultValue && input.onChange(defaultValue);
        return <input type="hidden" {...input} />;
      case "select":
        return (
          <Select
            {...input}
            value={selected && (typeof selected.value !== "undefined" || selected.length)
              ? selected
              : defaultValue
            }
            onChange={onSelectChange}
            onBlur={() => input.onBlur(input.value)}
            options={options}
            className="custom-select-box"
            isMulti={multiple}
            isOptionDisabled={isOptionDisabled}
            placeholder={isLoading ? "Loading..." : (placeholder || "Select")}
            isDisabled={disable || isLoading}
            isLoading={isLoading}
            components={{
              NoOptionsMessage: (props) => <NoOptionsMessage {...props} isLoading={isLoading} />,
              Input: props => (
                <CustomInput
                  {...props}
                />
              ),
              MenuList: VirtualizedMenuList
            }}
            styles={doughnutStyles}
            ref={zipRef}
          />
        );
      case "selectForZipCodes":
        {
          const currentValue = selected !== null ? selected : defaultValue;

          return (
            <Select
              {...input}
              value={currentValue}
              onChange={onSelectChange}
              onBlur={() => input.onBlur(input.value)}
              options={options}
              className="custom-select-box test-2"
              isMulti={multiple}
              isOptionDisabled={isOptionDisabled}
              placeholder={placeholder ? placeholder : "Select or paste zipcodes"}
              isDisabled={disable}
              formatOptionLabel={formatOptionLabel}
              styles={{
                ...doughnutStyles,
                ...sharedSelectStyles
              }}
              ref={zipRef}
              components={{
                Input: props => (
                  <CustomInput
                    {...props}
                    onPaste={(e) => handleZipCodePaste(e)}
                  />
                ),
                MenuList: VirtualizedMenuList
              }}
              isClearable={true}
            />
          );
        }
      case "selectWithAddForZipCodes": {
        const currentValue = selected !== null ? selected : defaultValue;

        return (
          <CreatableSelect
            {...input}
            value={currentValue}
            onChange={onSelectChange}
            onKeyDown={onSelectKeydown}
            onBlur={() => input.onBlur(input.value)}
            options={options}
            className="custom-select-box"
            isMulti={multiple}
            isOptionDisabled={isOptionDisabled}
            placeholder={placeholder ? placeholder : "Select"}
            isDisabled={disable}
            noOptionsMessage={() => "Zip Code already exists"}
            isValidNewOption={formatCreateLabel}
            components={{
              Input: props => (
                <CustomInput
                  {...props}
                  onPaste={(e) => handleZipCodePaste(e, true)}
                />
              ),
              MenuList: VirtualizedMenuList
            }}
            styles={sharedSelectStyles}
          />
        );
      }
      case "boolean":
        return (
          <div className="input-container">
            <ToggleButton {...input} isDisabled={disable} tooltip={tooltip} />
          </div>
        );
      case "radio":
        return (
          <div className="radio">
            <input
              {...input}
              className="form-control"
              type="radio"
              disabled={disable}
            />
            <span className="radio-label">{radioLabel}</span>
          </div>
        );
      case "datepicker":
        return (
          <DatePicker
            onChange={input.onChange}
            selected={selectedDate}
            name={input.name}
            autoComplete="off"
            minDate={minDate}
            maxDate={maxDate}
            dateFormat={datePickerDateFormat}
            wrapperClassName="datePicker1"
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            placeholderText={placeholderText}
          />
        );
      case "file":
        return (
          <div className="input-file-container">
            <FileUpload
              {...input}
              defaultValue={defaultValue}
              multiple={multiple}
              onChange={onFileChange}
              onError={onError}
              isLoading={isLoading}
              resizeImage={resizeImage}
              isDisabled={disable}
              placeholder={placeholder}
              resize={resize}
              minWidth={minWidth}
              minHeight={minHeight}
              imageOnly={imageOnly}
              maxSize={maxSize}
              disableThumbnail={disableThumbnail}
            />
          </div>
        );
      case "textarea":
        return (
          <div className="input-textarea-container">
            <textarea {...input} className="form-control" disabled={disable} />
          </div>
        );
      case "rte":
        return (
          <div className="input-rte-container">
            <CKEditor
              editor={ClassicEditor}
              data={RTEValue}
              config={{
                toolbar: [
                  "fontFamily",
                  "bold",
                  "italic",
                  // "Strike",
                  // "Underline",
                  "NumberedList",
                  "BulletedList",
                  "blockQuote",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              onChange={onRTEChange}
              isDisabled={disable}
            />
          </div>
        );
      case "rte1":
        return (
          <div className="input-rte-container">
            <Editor
              initialValue={RTEValue}
              tinymceScriptSrc={`/tinymce/tinymce.min.js`}
              init={{
                height: 200,
                menubar: false,
                branding: false,
                plugins: ["link"],
                content_css: [
                  "//fonts.googleapis.com/css?family=Indie+Flower",
                  "//fonts.googleapis.com/css2?family=Chilanka&family=Sriracha&display=swap",
                ],
                font_formats:
                  "Arial=arial,helvetica,sans-serif;" +
                  "Arial Black=arial black,avant garde;" +
                  "Book Antiqua=book antiqua,palatino;" +
                  "Comic Sans MS=comic sans ms,sans-serif;" +
                  "Courier New=courier new,courier;" +
                  "Georgia=georgia,palatino;" +
                  "Helvetica=helvetica;" +
                  "Impact=impact,chicago;" +
                  "Tahoma=tahoma,arial,helvetica,sans-serif;" +
                  "Terminal=terminal,monaco;" +
                  "Times New Roman=times new roman,times;" +
                  "Trebuchet MS=trebuchet ms,geneva;" +
                  "Verdana=verdana,geneva;",
                toolbar: `undo redo | ${customeTag ? "customInsertButton" : null
                  } fontsizeselect fontselect | bold italic underline backcolor link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat`,
                setup: function (editor) {
                  editor.ui.registry.addButton("customInsertButton", {
                    icon: "user",
                    tooltip: "Insert Recipient's Name",
                    onAction: function (_) {
                      editor.insertContent("{ Recipient name }");
                    },
                  });
                },
              }}
              onEditorChange={onRTE1Change}
            ></Editor>
          </div>
        );
      case "rte2":
        return (
          <div className="input-rte-container">
            <Editor
              initialValue={RTEValue}
              tinymceScriptSrc={`/tinymce/tinymce.min.js`}
              init={{
                height: 160,
                menubar: false,
                branding: false,
                statusbar: false,
                fontsize_formats: "11px 12px 14px 16px",
                content_css: [
                  "//fonts.googleapis.com/css2?family=Alegreya&family=Alegreya+Sans&family=Alfa+Slab+One&family=Anton&family=Architects+Daughter&family=Archivo&family=Archivo+Black&family=B612&family=BioRhyme&family=Cairo&family=Caveat&family=Crimson+Text&family=Dawning+of+a+New+Day&family=EB+Garamond&family=Fjalla+One&family=Frank+Ruhl+Libre&family=Gloria+Hallelujah&family=IBM+Plex+Serif&family=Karla&family=Lato&family=Liu+Jian+Mao+Cao&family=Lora&family=Merriweather&family=Montserrat&family=Mulish&family=Open+Sans&family=PT+Serif&family=Permanent+Marker&family=Playfair+Display&family=Prata&family=Raleway&family=Reenie+Beanie&family=Roboto&family=Roboto+Slab&family=Rubik&family=Source+Sans+Pro&family=Special+Elite&family=Spectral&family=Titillium+Web&family=Varela&family=Vollkorn&display=swap",
                ],
                font_formats: googleFonts.join(";"),
                toolbar: `undo redo | fontsizeselect fontselect | bold underline backcolor link | alignleft aligncenter alignright alignjustify | removeformat`,
              }}
              onEditorChange={onRTE1Change}
            ></Editor>
          </div>
        );

      default:
        const field = (
          <input
            {...input}
            autoComplete="off"
            className="form-control"
            type={
              type === "password"
                ? toggleShowPassword
                  ? "text"
                  : "password"
                : inputType
            }
            value={isLoading ? "Loading..." : input.value}
            disabled={isLoading || disable}
            maxLength={maxlength ? maxlength : 1000}
          />
        );
        return type === "checkbox" ? (
          <label className={`m-0 ${disable ? "disabled" : ""}`}>
            {field}
            <span className="form-checkbox" />
          </label>
        ) : (
          field
        );
    }
  };

  const renderInfo = () => {
    return info ? (
      <Tooltip
        message={info}
        component={
          <span className="info-icon">
            <i className="icon icon-info" />
          </span>
        }
      />
    ) : null;
  };

  const nonFormElements = ["h1", "h2", "h3", "h4", "div", "badges", "hidden"];

  return hide ? null : nonFormElements.includes(type) ? (
    renderInput()
  ) : (
    <div
      className={`form-group ${disable ? "disabled" : ""
        } ${type} ${formGroupClass} ${touched ? (error ? "error" : warning ? "warning" : "") : ""
        } ${matchWith &&
          matchWith.formValues &&
          matchWith.formValues[matchWith.field] &&
          input.value
          ? matching
            ? "matching"
            : "not-matching"
          : ""
        } ${viewMode ? "view-mode" : ""}`}
    >
      {touched &&
        ((error && <span className="span-error">{error}</span>) ||
          (warning && <span className="span-warning">{warning}</span>))}
      {renderInput()}
      {label || info ? (
        <span className="form-label">
          {label} {renderInfo()}
        </span>
      ) : null}
      {type === "password" && showPassword ? (
        <div
          className="view-password-btn"
          onClick={() => setToggleShowPassword(!toggleShowPassword)}
        >
          <i className={`icon icon-${toggleShowPassword ? "hide" : "show"}`} />
        </div>
      ) : null}
    </div>
  );
};

export default FormGroup;
