import React from "react";
import {
  email,
  min1,
  normalizePhone,
  required,
  USPhoneNumber,
} from "../../../constant/formValidators";
import { userRoles, userTypes } from "../../../constant/userManagement";

export const formFields = ({
  setError,
  createMode,
  profileEdit,
  userDetails,
  formValues,
  companyNames,
  fetchCompanyNames,
  initialValues,
  isLicenseReallocation = false,
}) => {
  const loggedInAs = (type) => {
    return userDetails.userType === userTypes[type];
  };

  const companyCondition =
    isLicenseReallocation ||
    (loggedInAs("AD") && profileEdit) ||
    (loggedInAs("CA") && !profileEdit) ||
    loggedInAs("SM") ||
    loggedInAs("BO") ||
    (initialValues && initialValues.userType === userTypes.CA && !profileEdit);

  const permissionCondition =
    isLicenseReallocation ||
    (userDetails && (loggedInAs("SM") || loggedInAs("BO") || profileEdit));

  const emailFieldHide = !createMode && !isLicenseReallocation;

  const statusHide = createMode || profileEdit || isLicenseReallocation;

  return [
    {
      name: "h3User",
      type: "h3",
      label: loggedInAs("AD") ? "Admin" : "User",
      className: "color-1 mt-3 mb-3 text-uppercase col-12",
    },
    {
      name: "uploadedFile64",
      label: "Profile Image",
      type: "file",
      hide: !profileEdit,
      multiple: false,
      defaultValue: formValues && formValues.profileImageURL,
      onChange: () => setError(null),
      formGroupClass: "col-12",
    },
    {
      name: "isActive",
      label: "Status",
      type: "boolean",
      hide: statusHide,
      onChange: () => setError(null),
      formGroupClass: "col-12",
    },
    {
      name: "name",
      label: "First Name",
      type: "text",
      onChange: () => setError(null),
      formGroupClass: "col-12",
      validate: [required],
      maxlength: 64,
    },
    {
      name: "surname",
      label: "Last Name",
      type: "text",
      onChange: () => setError(null),
      formGroupClass: "col-12",
      validate: [required],
      maxlength: 64,
    },
    {
      name: "emailAddress",
      label: "Email Address",
      type: "email",
      hide: emailFieldHide,
      onChange: () => setError(null),
      formGroupClass: "col-12",
      validate: [required, email],
      maxlength: 256,
    },
    {
      name: "personalPhoneNo",
      label: "Mobile Number",
      type: "text",
      onChange: () => setError(null),
      formGroupClass: "col-12",
      validate: [required, USPhoneNumber],
      normalize: normalizePhone,
    },
    {
      name: "userType",
      label: "Role",
      type: "select",
      options: roleNamesOptions(userDetails),
      hide: !loggedInAs("CA") || profileEdit || !createMode,
      onChange: (value) => {
        setError(null);
        fetchCompanyNames && fetchCompanyNames(value);
      },
      defaultValue:
        (formValues &&
          userRoles.filter((item) => item.value === formValues.userType)[0]) ||
        null,
      formGroupClass: "col-12",
      validate: loggedInAs("CA") && !profileEdit ? [required] : null,
    },
    {
      name: "companyNameObj",
      label: "Submarket Owner",
      type: "select",
      hide:
        loggedInAs("CA") ||
        !(
          formValues &&
          formValues.userType &&
          (formValues.userType === userTypes.BO ||
            formValues.userType.value === userTypes.BO)
        ) ||
        !createMode,
      options: companyNames.map((item) => {
        return {
          label: item.companyName || item.fullName,
          value: item.userId,
        };
      }),
      formGroupClass: "col-12",
      onChange: () => setError(null),
      // validate:
      //     formValues &&
      //     formValues.userType &&
      //     formValues.userType.value === userTypes.BO
      //         ? [required]
      //         : null
    },
    //
    {
      name: "h3Company",
      type: "h3",
      label: "Company",
      hide: companyCondition,
      className: "color-1 mt-4 mb-3 text-uppercase col-12",
    },
    {
      name: "companyName",
      label: "Company Name",
      type: "text",
      onChange: () => setError(null),
      formGroupClass: "col-12",
      hide: companyCondition,
      validate: [required],
      maxlength: 64,
    },
    {
      name: "officePhoneNo",
      label: "Phone Number",
      type: "text",
      onChange: () => setError(null),
      formGroupClass: "col-12",
      hide: companyCondition,
      validate: [required, USPhoneNumber],
      normalize: normalizePhone,
    },
    {
      name: "address1",
      label: "Address 1",
      type: "text",
      onChange: () => setError(null),
      hide: companyCondition,
      formGroupClass: "col-12",
    },
    {
      name: "address2",
      label: "Address 2",
      type: "text",
      onChange: () => setError(null),
      hide: companyCondition,
      formGroupClass: "col-12",
    },
    //
    {
      name: "h3Permissions",
      type: "h3",
      label: "Permissions",
      hide:
        isLicenseReallocation ||
        profileEdit ||
        loggedInAs("SM") ||
        loggedInAs("BO"),
      className: "color-1 mt-4 mb-3 text-uppercase col-12",
    },
    {
      name: "noOfSeats",
      label: "Number of Seats",
      type: "number",
      onChange: () => setError(null),
      formGroupClass: "col-8",
      hide: permissionCondition || loggedInAs("CA"),
      validate: [required, min1],
    },
    {
      name: "fullService",
      label: "Full Service",
      type: "boolean",
      onChange: () => setError(null),
      hide: permissionCondition || loggedInAs("CA"),
      formGroupClass: "col-12",
    },
    {
      name: "homeOwnerDataAccessLimit",
      label: "Home Owner Data Access Limit",
      type: "number",
      hide:
        permissionCondition ||
        (formValues && formValues.fullService) ||
        loggedInAs("CA"),
      onChange: () => setError(null),
      formGroupClass: "col-8",
      validate: [min1],
    },
    {
      name: "accessToTemplates",
      label: "Access to Templates",
      type: "boolean",
      hide: isLicenseReallocation || profileEdit || !loggedInAs("CA"),
      onChange: () => setError(null),
      formGroupClass: "col-12",
    },
    {
      name: "licenseReallocationReason",
      label: "Reason for Reallocation",
      type: "textarea",
      hide: !isLicenseReallocation,
      onChange: () => setError(null),
      formGroupClass: "col-12",
      placeholder: "Enter reason for license reallocation...",
    },
    {
      name: "reallocationNote",
      type: "div",
      hide: !isLicenseReallocation,
      className: "col-12 mt-4",
      render: () => (
        <div className="alert alert-info">
          <i className="icon icon-info-circle mr-2"></i>
          <strong>Important:</strong> When reallocating a license:
          <ul className="mt-2 mb-0">
            <li>
              All account data and history will be transferred to the new user
            </li>
            <li>The new user will receive an email to set up their password</li>
            <li>
              The previous user's access will be revoked immediately upon
              reallocation
            </li>
          </ul>
        </div>
      ),
    },
  ];
};

export const roleNamesOptions = (userDetails) => {
  switch (userDetails.userType) {
    case userTypes.AD:
      return userRoles;
    case userTypes.CA:
      return [userRoles[2], userRoles[3]];
    case userTypes.SM:
      return [userRoles[3]];
    default:
      return [];
  }
};
