import React from "react";
import { Carousel } from "react-bootstrap";
import { Date } from "../../../utils/formatter";
import { dateFormat } from "../../../constant/global";
import noImage from "../../../assets/no-image.png";
import openHouseImg from "../../../assets/home.png";
import { formatOpenHouseDate } from "../../../utils/openHouseDate";

const SliderBlock = ({ propertyDetails, isAdmin }) => {
  const { formatDate, timeZone } = formatOpenHouseDate(
    propertyDetails?.openHouse
  );
  return (
    <>
      <div className="col-7 pr-0">
        <>
          {propertyDetails?.openHouse ? (
            <div className="d-flex flexDirection:'row'">
              <div className="text-success ml-2 d-flex align-items-center">
                <div
                  className={`open-house badge badge-${true ? "success" : "secondary"
                    }`}
                >
                  <img
                    className="mr-1"
                    src={openHouseImg}
                    alt="open house image"
                  />
                  Open House -{" "}
                  {`${formatDate}${timeZone ? `, ${timeZone}` : ""}`}
                </div>
              </div>
            </div>
          ) : null}

          {propertyDetails?.bookedDate ? (
            <div className="ribbon">
              <span className="ribbon5">
                Booked on{" "}
                <Date
                  utcTime={propertyDetails.bookedDate}
                  format={dateFormat}
                />
              </span>
            </div>
          ) : null}

          {propertyDetails?.propertyImages ? (
            <Carousel
              nextIcon={
                <span
                  aria-hidden="true"
                  className="carousel-control-next-icon btn-bottom"
                ></span>
              }
            >
              {propertyDetails.propertyImages.map((item, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={item.imageUrl}
                    alt="First slide"
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <div>
              <img className="no-image w-100" src={noImage} alt="No Data" style={{ height: "400px", paddingBottom: isAdmin ? "20px" : "0px" }}></img>
            </div>
          )}
        </>
      </div>
    </>
  );
};
export default SliderBlock;
